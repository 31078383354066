import React from 'react';
import { StyledScrollContainer } from './StyledScrollContainer';

interface Props {
    onScrollTop?: () => void,
    children?: JSX.Element | (JSX.Element | null | any)[] | null, refElement?: any,
    width?: string
}

export const ScrollBar = ({ children, onScrollTop, refElement, width}: Props) => {

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        if(onScrollTop && e.currentTarget.scrollTop === 0) {
            onScrollTop()
        }
    }
    return (
        <StyledScrollContainer ref={refElement} id='wbb-unique-scrollbar' width={width} onScroll={handleScroll} role="status" aria-atomic="false" aria-busy="false">
            {children}
        </StyledScrollContainer>
    )
}