import styled from "styled-components";

export const StyledWidgetContainer = styled.div<{openOnStart?: boolean, fullScreen?: boolean, left?:boolean}>
    `
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.main};
    border-radius: ${({ theme, fullScreen, left}) => fullScreen ?  '0px' : left ? theme.additionalStyles.bordersRadius.leftSideChatContainer :theme.additionalStyles.bordersRadius.chatContainer};
    box-shadow: -3px 6px 6px 1px #00000040; // from design
    display: ${props => props.openOnStart ? 'flex': 'none'};
    border: ${({ theme, fullScreen }) => fullScreen ? 'none'  : '1px solid ' + theme.colors.primary.main};
    position: relative;
`;
