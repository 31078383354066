import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons"
import { HeaderButtonWrapper, ControlsButtons } from "../IconButton/IconButtonOnPrimary";
import { ButtonSizes } from '../Button/Button.types';

export function MinimizeWindow({onClick}:  {onClick: (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void}) {
    return (
        <HeaderButtonWrapper>
        <ControlsButtons  onClick={onClick} size={ButtonSizes.small}>
        <FontAwesomeIcon icon={faWindowMinimize}  title='Hide chat window, this button will minimize chat but will not clear conversation.' />
        </ControlsButtons>
        </HeaderButtonWrapper>
    )
}

