import styled from "styled-components"


export const ListItemWrapper = styled.div`
border: 1px solid ${({ theme }) => theme.colors.primary.main};
display: flex;
flex-direction: row;
padding: 5px;
border-radius: 6px;
display: -webkit-flex; 
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
`
export const ImageWrapper = styled.div`
flex: 1;
`

export const LeftSide = styled.div`
flex: 3;
padding-right: 5px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Text = styled.div`
  margin-bottom: 5px;
`

export const Title = styled.h4`
  font-weight: bold;
  margin: 0px;
`

export const Img = styled.img`
  width: 100%;
  border-radius: 0 6px 6px;
`

export const ListWrapper = styled.div`
  max-width: 75%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 8px;
`