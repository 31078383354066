import React from "react";
import { PropsWithChildren } from "react";
import { getTheme } from "./defineLayout";
import { APiPluginStyle } from "../components/ThemeProvider/ThemeProvider.types";
import { Theme } from "../components/ThemeProvider/ThemeProvider";

export const ChatLayout = ({ apiStyle, windowStyle, children }: PropsWithChildren<{ apiStyle: APiPluginStyle, windowStyle: any}>) => {
    const theme = getTheme(apiStyle, windowStyle)
    return (
        <Theme clientTheme={theme}>
            {children}
        </Theme>
    )
}
