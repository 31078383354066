import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../IconButton";
import { useMessagesContext } from "../MessagesContainer/MessageProvider";
import {  useOverlapContext } from "../OverlapProvider/OverlapProvider";
import { OverlapComponentTypes } from "../OverlapProvider/OverlapProvider.types";
import { ButtonColors, ButtonSizes } from "../Button/Button.types";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";

function DebugInfo() {
    const { debugInfo } = useMessagesContext()
    const { onOpen } = useOverlapContext()

    function handleOpen() {
        onOpen({ componentType: OverlapComponentTypes.DebugInfo, props: debugInfo })
    }
    if (!debugInfo) {
        return null
    }

    return (
        <IconButton color={ButtonColors.tertiary} size={ButtonSizes.small} onClick={handleOpen} >
            <FontAwesomeIcon icon={faCircleInfo} />
        </IconButton>
    )
}

export default DebugInfo;