import { KeyboardEventHandler } from "react";

export interface ButtonProps
  extends Omit<ButtonStyledProps, "startIcon" | "endIcon"> {
  text?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  id?: string;
  tooltip?: string;
  choice?: boolean;
}
export interface ButtonStyledProps {
  color?: ButtonColors;
  variant?: ButtonVariants;
  disabled?: boolean;
  size?: ButtonSizes;
  ["data-start"]?: boolean;
  ["data-end"]?: boolean;
  choice?: boolean;
  tabIndex?: number;
}

export enum ButtonVariants {
  filled = "filled",
  outlined = "outlined",
  text = "text",
  elevated = "elevated",
  toned = "toned",
}
export enum ButtonColors {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  error = "error",
}
export enum ButtonSizes {
  small = "small",
  medium = "medium",
  large = "large",
}
