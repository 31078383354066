var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CONFIG } from '../../config';
import { io } from 'socket.io-client';
import { createContext, useEffect, useContext, useRef, } from 'react';
var SocketContext = createContext(null);
var URL = CONFIG.socketServerUrl + '?userSid=';
export var SocketProvider = function (_a) {
    var userSid = _a.userSid, dispatchWbbEvent = _a.dispatchWbbEvent, children = _a.children, dispatchTypingEvent = _a.dispatchTypingEvent;
    var socketRef = useRef(io(URL + userSid));
    useEffect(function () {
        if (!userSid)
            return;
        var socket = socketRef.current;
        function onConnect() {
            socket.emit('authentication', {});
        }
        function onSuccessfullyAuthenticated() {
            console.log('socket successfullyAuthenticated');
        }
        function onDisconnect(reason) {
            if (reason === 'io server disconnect') {
                return socket.connect();
            }
            else {
                console.log("Disconnected : ".concat(reason));
            }
        }
        function onWbbEvent(event) {
            dispatchWbbEvent(event);
        }
        function onAgentTyping(event) {
            dispatchTypingEvent(__assign(__assign({}, event), { type: "typing", created: new Date() }));
        }
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('successfullyAuthenticated', onSuccessfullyAuthenticated);
        socket.on('wbbEvent', onWbbEvent);
        socket.on('agentTyping', onAgentTyping);
        return function () {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('successfullyAuthenticated', onSuccessfullyAuthenticated);
            socket.off('wbbEvent', onWbbEvent);
            socket.off('agentTyping', onAgentTyping);
        };
    }, [userSid, dispatchWbbEvent, dispatchTypingEvent]);
    return _jsx(SocketContext.Provider, { value: null, children: children });
};
export var useSockets = function () {
    return useContext(SocketContext);
};
