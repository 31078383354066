import { Polylang } from '../session/Polylang';
import { Action, Choice } from './Choice';

export interface Block {
    _id: string;
    inUse?: boolean;
    module?: string;
    blockId: string;
    blockVersion: number;
    content: Content;
    created: string;
    defaultChoice?: any;
    function?: FunctionObj;
    group: string;
    meta: Meta;
    name: string;
    type: BlockTypes;
    version: number;
    clientBlock: boolean;
    overrideBlock: boolean;
    next?: string | null;
    variables?: Variable[];
    onReply: {
        blockId: string;
        args?: any;
    };
    visualBotEditor?: {
        x?: number;
        y?: number;
    };
    eventIds?: [] | string[];
    root?: boolean;
    icon?: string;
    iconColour?: string;
    backgroundColour?: string;
}

interface Variable {
    title: string;
    value: string;
}

interface Meta {
    complexBlockType?: ComplexBlockTypes;
    imageAspectRatio?: string;
    messenger?: any;
    topElementStyle?: string;
    choiceType?: ChoiceTypes;
    description?: string;
}

interface Content {
    items?: Item[];
    text: Text;
    title: Polylang;
    image?: string;
    video?: string;
}

interface Item {
    title: Polylang;
    subtitle: Polylang;
    choices: Choice[] | never[];
    image?: string;
    video?: string;
}

interface FunctionObj {
    name?: string;
    useAllFormValues?: boolean;
    args?: Args | Record<string, any>;
    todo?: boolean;
    tempText?: Polylang;
    tempChoices?: Choice[];
    formId?: string;
    type?: FunctionTypes;
    emailFormAsTable?: {
        toEmail: string;
        fromEmail: string;
        subject: string;
        unset: boolean;
    };
    handlerName?: string;
    logic?: {
        formId: string;
        defaultMappingBlockId: string;
        expressions: { condition: {}; blockId: string }[];
    };
}

interface Args {
    channel: string;
    next: string;
}

interface Text {
    default: Polylang;
}

enum FunctionTypes {
    EMAIL_FORM_AS_TABLE = 'email-form-as-table',
    SALESFORCE_CREATE_CASE = 'salesforce-create-case',
    UNSET_FORM = 'unset-form',
    ZAPIER_TRIGGER = 'zapier-trigger',
    LOGIC_BLOCK = 'logic-block',
}

export enum ChoiceTypes {
    QREPLY = 'qreply',
    QUESTION = 'question',
}

export enum ComplexBlockTypes {
    LIST = 'list',
    GENERIC = 'generic',
}

export enum BlockTypes {
    MESSAGE = 'text',
    SELECT = 'select',
    MEDIA = 'media',
    FUNCTION = 'function',
    VARIABLE = 'variable',
    COMPLEX = 'complex',
    KNOWLEDGE = 'kb'
}

export interface ProceededBlock extends Omit<Block, 'content'> {
    text: string;
    content: PreprocessedBlockContent;
    formFieldCallback: {
        args: any
    },
    events?: string[]
}

export interface PreprocessedBlockContent {
    text: string;
    title?: Polylang;
    items?: PreprocessedBlockItem[];
    image?: any;
}

export interface PreprocessedBlockItem {
    choices: PreprocessedBlockChoice[];
    title?: any;
    subtitle?: any;
    defaultChoice?: any;
    image?: string;
    video?: string;
}

export interface PreprocessedBlockChoice {
    title: string;
    action: Action;
    selectBlock: SelectBlock;
    selectedTitle: string;
    serialized: string;
    session?: MessageSession;
    functionArgs?: FunctionArgs;
    userStatus?: string;
}

interface MessageSession {
    nextInterventionChannel: string;
}

interface FunctionArgs {
    skipEmail: boolean;
}

interface SelectBlock {
    id: string;
    blockVersion: number;
}

export const LOCATION_FORM_FIELD_TYPE = 'location-coords'