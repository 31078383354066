import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { HeaderAlign, HeaderProps } from "./Header.types";
import {
  Col,
  ControlsButtonsRow,
  LogoTitleRow,
  Row,
  ActionContainer,
  StyledHeader,
  Subtitle,
  Title,
} from "./StyledHeader";
import Avatar from "../Avatar";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import { ExitFullScreen } from "./ExitFullScreen";
import { ExpandScreen } from "./ExpandScreen";
import { DownloadChat } from "./DownloadChat";
import EndChat from "./EndChat";
import { MinimizeWindow } from "./MinimazeWindow";
import { ControlsButtons } from "../IconButton/IconButtonOnPrimary";
import { ButtonSizes } from "../Button/Button.types";
import { Tooltip } from "../Tooltip";
import styled from "styled-components";
import { SrOnly } from "../SrOnly/SrOnly";

export const LOGO_ALT = "company-logo";
export const HEADER_TEST_ID = "chat-header";

const Header: FC<HeaderProps> = ({
  companyLogo,
  title,
  subtitle,
  align = HeaderAlign.left,
  companyName = '',
  onToggle,
  onClickDownloadChat,
  onClickEndChat,
}) => {
  const {
    fullScreen,
    toggleFullScreen,
    enableFullScreen,
    isMobileOrSmallScreen,
    enableDownloadChat,
    logicdialogPreview,
  } = useClientSettings();

  const handleExpandScreen = () => {
    if (toggleFullScreen) {
      toggleFullScreen();
    }
  };

  const handleExitFullScreen = () => {
    if (toggleFullScreen) {
      toggleFullScreen();
      if (isMobileOrSmallScreen) onToggle(); // close chat mobile if it is fullScreen because of the device
    }
  };

  const companyLogoAlt = LOGO_ALT.replace('company', companyName)
  let chatSizeStatus = enableFullScreen && !fullScreen ? 'Chat window expanded to the standard size' : 'Chat window expanded to the full screen' 
  if(isMobileOrSmallScreen) chatSizeStatus = ''

  return (
    <StyledHeader
      align={align}
      data-testid={HEADER_TEST_ID}
      fullScreen={fullScreen}
    >
      <Row align={align}>
        <LogoTitleRow>
          {companyLogo?.show && (
            <Avatar src={companyLogo?.url} alt={companyLogoAlt} crossOrigin={logicdialogPreview ? "anonymous" : undefined}/>
          )}
          <Col>
            <Title align={align} id='wbb-chat-widget-title'>{title}</Title>
            <Subtitle align={align}>{subtitle}</Subtitle>
          </Col>
        </LogoTitleRow>
        <ActionContainer>
          <ControlsButtonsRow>
            {!logicdialogPreview && (
              <>
                <SrOnly role="status">{chatSizeStatus}</SrOnly>
                <MinimizeWindow onClick={onToggle} />
                {enableFullScreen && fullScreen && !isMobileOrSmallScreen && (
                  <ExitFullScreen onClick={handleExitFullScreen} />
                )}
                {enableFullScreen && !fullScreen && (
                  <ExpandScreen onClick={handleExpandScreen} />
                )}
                {onClickEndChat && <EndChat onClickEndChat={onClickEndChat} />}
              </>
            )}
            {logicdialogPreview && onClickEndChat && (
              <Tooltip content="Reset Conversations">
                <RestartControlsButton onClick={onClickEndChat} size={ButtonSizes.small}>
                  <FontAwesomeIcon
                    icon={faArrowRotateRight}
                    title="Reset Conversations"
                  />
                </RestartControlsButton>
              </Tooltip>
            )}
          </ControlsButtonsRow>
          {!logicdialogPreview && enableDownloadChat && (
            <DownloadChat onClick={onClickDownloadChat} />
          )}
        </ActionContainer>
      </Row>
    </StyledHeader>
  );
};

export default Header;

const RestartControlsButton = styled(ControlsButtons)`
& > svg.fa-arrow-rotate-right {
  height: 12px;
} 
`;