import React, { useState } from "react"
import { faThumbsUp as faThumbsUpFilled, faThumbsDown as faThumbsDownFilled } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Feedback as IFeedback } from "../../../src/types/events";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-light-svg-icons";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import { getVisibleHover } from "../Button/styleHelper";
import { ButtonColors } from "../Button/Button.types";
import { SrOnly } from "../SrOnly/SrOnly";

interface Props {
  eventId: string[];
  feedback: IFeedback;
  postFeedback: (params: any) => void;
}

export const Feedback: React.FC<Props> = ({ eventId, postFeedback, feedback }) => {
  const [feedbackState, setFeedbackState] = useState<{ positive: boolean } | undefined>(feedback);
  const { allowFeedback } = useClientSettings();

  const handlePostFeedback = (positive: boolean) => {
    postFeedback({ eventId, positive });
    setFeedbackState({ positive })
  }

  if (!allowFeedback) {
    return (<></>)
  }

  if (!feedbackState) {
    return (
      <FeedbackWrapper>
        <FeedbackButton onClick={() => handlePostFeedback(true)}>
          <FontAwesomeIconUp icon={faThumbsUp} flip="horizontal" title="Leave positive feedback to the bot message" />
        </FeedbackButton>
        <FeedbackButton onClick={() => handlePostFeedback(false)}>
          <FontAwesomeIcon$ icon={faThumbsDown} title="Leave negative feedback to the bot message" />
        </FeedbackButton>
      </FeedbackWrapper>
    )
  }

  return (
    <FeedbackWrapper>
      <SrOnly>{feedbackState.positive ? "Leaved positive feedback to the bot message" : "Leaved negative feedback to the bot message"}</SrOnly>
      <FeedbackButton onClick={() => handlePostFeedback(true)} >
        <FontAwesomeIconUp icon={feedbackState.positive ? faThumbsUpFilled : faThumbsUp} flip="horizontal" role="presentation" />
      </FeedbackButton>
      <FeedbackButton onClick={() => handlePostFeedback(false)} >
        <FontAwesomeIcon$ icon={!feedbackState.positive ? faThumbsDownFilled : faThumbsDown} role="presentation" />
      </FeedbackButton>
    </FeedbackWrapper >
  )
}


const FontAwesomeIcon$ = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
    &:is(:hover, :focus-visible, :active){
      background: ${({ theme }) => getVisibleHover(ButtonColors.primary, theme.colors.background.main, theme.colors)};
     }
`

const FontAwesomeIconUp = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primary.main};
    margin-bottom: 3px;
    margin-right: 3px;
    cursor: pointer;
    &:is(:hover, :focus-visible, :active){
      background: ${({ theme }) => getVisibleHover(ButtonColors.primary, theme.colors.background.main, theme.colors)};
     }
`

const FeedbackWrapper = styled.div`
    margin-left: auto;
    padding-left: 8px;
`

const FeedbackButton = styled.button`
  padding: 0px;
  margin: 2px;
  border: none;
  background: none;
  & > svg {
    height: 20px;
    width: 20px;
   }
`;