import { Session } from '../session';
import { Block, ProceededBlock } from './Block';
import { BlockPointer } from './BlockPointer';
import { Choice } from './Choice';
import { Intent } from './Intent';
import { ChannelsDate } from './RecordTypes';
import { MsgTypes } from './enum';

export interface WbbEventMessage extends Attachments {
    utteranceDeleted?: boolean;
    sent: ChannelsDate;
    delivered: ChannelsDate;
    read: ChannelsDate;
    type: MsgTypes;
    resetRequest?: boolean;
    blockRequest?: boolean;
    inputEventId?: string;
    returnBlock?: BlockPointer;
    formFieldCallback?: formFieldCallback;
    blocks?: Block[]; // The unevaluated blocks returned by block helper
    evaluatedBlocks?: Block[]; // The evaluated blocks in the order they were sent
    preprocessedBlocks?: ProceededBlock[]; // The evaluated blocks after they have been through preprocessing see {@link BlockPreprocessor}.
    selectedLang?: string;
    text?: MessageText;
    wit?: Record<string, any>;
    parsedText?: string;
    sentiment?: Sentiment;
    fileName?: string;
    location?: Location;
    url?: string;
    phone?: string;
    fbAdvertId?: string;
    choice: Choice;
    intent?: Intent;
    draft?: boolean;
} 


type Attachments = Record<AttachmentsTypeEvents, string[]>
export enum AttachmentsTypeEvents {
    file = 'file',
    image = 'image',
    video = 'video',
    audio = 'audio',
}
export enum AttachmentsType {
    file = 'file',
    image = 'image',
    video = 'video',
    audio = 'audio',
    url = 'url'
}

const {file, image, video, audio} = AttachmentsType
export const mediaKeys: AttachmentsType[] = [file, image, video, audio]

interface MessageText {
    text: string;
    englishParsedText?: string;
    language?: string;
    formValueText?: string;
}

interface formFieldCallback {
    args: Record<string, any>;
}

interface Sentiment {
    knowlbase?: {
        neg?: number;
        neu?: number;
        pos?: number;
        compound?: number;
    };
    watson?: {
        emotion?: any;
        sentiment?: any;
    };
    calculated?: {
        label: string;
        score: number;
    };
}

export interface SendTextMsgPayload extends Session {
    eventId: string;
    text: string;
}

export interface SendChoiceMsgPayload extends Session {
    eventId: string;
    choice: Choice;
}

export interface SendFileMsgPayload extends Session {
    eventId: string;
    blob: File;
}