import { Senders } from "../../types"

export function getMessageRowProps (event: any, operator: Senders){
    return ({
        content: event?.block?.content?.text,
        operator: event.deliveryInfo?.sender === operator,
        status: event?.deliveryInfo,
     } )
}

export const getBotEventsIds = (eventsList: any[]) => {
    // Find the last index where the sender is the user
    const lastIndex = eventsList.map(event => event.deliveryInfo?.sender).lastIndexOf(Senders.USER);

    // Filter out events sent by the user that come after the last user event
    return eventsList
        .slice(0, lastIndex === -1 ? undefined : lastIndex) // Take events until the last user event, or all if no user event is found
        .filter(event => event.deliveryInfo?.sender === Senders.BOT || event.deliveryInfo?.sender === Senders.AGENT)
        .reduce((botEventIds, event) => {
            if (!botEventIds.includes(event.id)) {
                botEventIds.push(event.id);
            }
            return botEventIds;
        }, []);
};