import React, { FC, useEffect, useRef, useState } from 'react';
import { TextContainerProps } from './TextContainer.types';
import { Container, InputDiv, Divider, TransitionElementsWrapper, HIDE_TEXT_ROW, SHOW_MEDIA_ROW, SHOW_TEXT_ROW, HIDE_MEDIA_ROW, DividerDiv } from './styledTextContainer';
import { Senders } from '../../types/events/enum';
import MediaPanel from './MediaPanel';
import { CSSTransition } from 'react-transition-group'
import InputPanel from './InputPanel/InputPanel';
import { useClientSettings } from '../ClientSettingsProvider/ClientSettingsProvider';
import styled from "styled-components";

const TextContainer: FC<TextContainerProps> = ({ onSend, operatorTyping, isPortal, disabled}) => {
    const { fullScreen, showLeft } = useClientSettings()

    const [value, setValue] = React.useState('');
    const [showMediaPanel, setShowMediaPanel] = useState(false)
    const [startedTyping, setStartedTyping] = useState(false)

    const textNodeRef = useRef<HTMLDivElement>(null)
    const mediaNodeRef = useRef<HTMLDivElement>(null)

    const ref = useRef<(() => void) | never>();
    
    function onCustomerTyping() {
        operatorTyping(Senders.USER);
    }

    useEffect(() => {
        ref.current = onCustomerTyping;
      }, [onCustomerTyping]);

  
      const debouncedCallback = () =>  {
        const func =() => {
          ref.current?.();   
        };
        if (!startedTyping) {
            func();
            setStartedTyping(true);
             setTimeout(() => {
                setStartedTyping(false);
            }, 3000)
        }
      }
     
    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();
        debouncedCallback();
        setValue(event.currentTarget.value);
    }

    function handleSend(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        if (!value?.length) return;
        onSend(value)
        setValue('');
    }


    function handleKeyDown(event: any) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (!value?.length) return;
            onSend(value)
            setValue('');
        }
    }

    function handleToggleMediaPanel() {
        setShowMediaPanel(true)
    }

    function handleCloseMediaPanel() {
        setShowMediaPanel(false)
    }

    function onEnter() {
        if (textNodeRef.current && mediaNodeRef.current) {
            textNodeRef.current.className = HIDE_TEXT_ROW
            mediaNodeRef.current.className = SHOW_MEDIA_ROW
        }
    }

    function onExiting() {
        if (textNodeRef.current && mediaNodeRef.current) {
            textNodeRef.current.className = SHOW_TEXT_ROW
            mediaNodeRef.current.className = HIDE_MEDIA_ROW
        }
    }

    return (
        <Container fullScreen={fullScreen}>
            <DividerDiv>
                <Divider />
            </DividerDiv>
            <InputDiv left={showLeft}  fullScreen={fullScreen}>
                <CSSTransition$
                    in={showMediaPanel}
                    timeout={250}
                    onEnter={onEnter}
                    onExit={onExiting}
                >
                    <TransitionElementsWrapper>
                        <InputPanel disabled={disabled} value={value} textNodeRef={textNodeRef} onSend={handleSend} onToggleMediaPanel={handleToggleMediaPanel} onChange={handleChange} onKeyDown={handleKeyDown}  isPortal={isPortal}/>
                        <MediaPanel mediaNodeRef={mediaNodeRef} onToggle={handleCloseMediaPanel} setValue={setValue} />
                    </TransitionElementsWrapper>
                </CSSTransition$>
            </InputDiv>
        </Container>
    )
}

export default TextContainer;

const CSSTransition$ = styled(CSSTransition)`
    width: 100%;
`
