import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ButtonSizes, ButtonVariants } from "../Button/Button.types";
import { Languages, PreprocessedBlockChoice, PreprocessedBlockContent, PreprocessedBlockItem } from "../../types";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import { BackgroundImage, ContentWrapper, SliderButtonWrapper, SliderItemWrapper, StyledSlider, TextWrapper, Title, Video } from "./StyledSlider";
import FullWidthButton from "../Button/FullWidthButton";
import {  MsgBubble } from "../MessagesContainer/StyledMessageContainer";
import { SliderArrowLeft, SliderArrowRight } from "./SliderArrow";
import { useLanguageContext } from "../LanguageProvider";

interface Props {
  content: PreprocessedBlockContent,
  onSend: any,
}

interface SliderProps {
  item: PreprocessedBlockItem;
  onSend: any;
}

export const GallerySlider: React.FC<Props> = ({ content, onSend }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrowRight />,
    prevArrow: <SliderArrowLeft />,
  };
  return (
    <StyledSlider {...settings} >
      {content?.items?.map((item, index: number) => (<SliderItem key={index} item={item} onSend={onSend} />))}
    </StyledSlider>
  );
}

const SliderItem: React.FC<SliderProps> = ({ item, onSend }) => {
  const { fullScreen } = useClientSettings();
  const {lang} = useLanguageContext()
  const hasChoices = item.choices.length > 0;

  function handleSelectChoice(choice: PreprocessedBlockChoice) {
    onSend(JSON.parse(choice.serialized))
  }
  return (
    <SliderItemWrapper>
      <ContentWrapper>
      {item.image && <BackgroundImage image={item.image} fullScreen={fullScreen} />}
      {item.video && <Video controls><source src={item.video} /></Video>}
      </ContentWrapper>
      <TextWrapper>
        <MsgBubble >
          <Title>{item.title}</Title>
          <div>{item.subtitle}</div>
        </MsgBubble>
        <SliderButtonWrapper>
          {hasChoices && item.choices.map((choice, index) =>
            <FullWidthButton
              key={index}
              variant={ButtonVariants.outlined}
              size={ButtonSizes.medium}
              text={typeof choice?.title === 'string' ? choice?.title : choice?.title?.[lang] || choice?.title?.[Languages.ENGLISH]}
              id={choice?.action?.value}
              onClick={() => handleSelectChoice(choice)}
            />)}
        </SliderButtonWrapper>
      </TextWrapper>
    </SliderItemWrapper>
  )
}