import React from "react";
import styled from "styled-components";
import { useMessagesContext } from "../MessageProvider"
import { InfoDiv } from "./StyledInfoRow";

function CustomEventsInfo() {
    const { events, isPortal
    } = useMessagesContext()

    const getStartText = (events?: string[]) => {
        if (events) {
            const length = events.length
            if (length === 1) return 'Event'
            if (length > 1) return 'Events'
        }
        return '';
    }
    const getEndText = (events?: string[]) => {
        if (events) {
            const length = events.length
            if (length === 1) return ' was triggered'
            if (length > 1) return ' were triggered'
        }
        return '';
    }

    const startText = getStartText(events)
    const endText = getEndText(events)

    if (!events?.length || !isPortal) return null;

    return (
        <StyledCustomEvent>
            {startText}
            <>
                {events.map((event, index) => <span>{` ${event}
                ${events.length > 1 && events.length - 1 === index ? ',' : ''}`}</span>)}
            </>
            {endText}
        </StyledCustomEvent>
    )

}

export default CustomEventsInfo

const StyledCustomEvent  = styled(InfoDiv)`
text-transform: none;
& > span {
    text-transform: uppercase;

}
`;