import React, { FC, useEffect } from "react";
import {
  ItemOne,
  ItemThree,
  ItemTwo,
} from "../MessagesContainer/LoadingElement/StyledLoadingItem";
import { MessageRow } from "../MessagesContainer/MessageRow";
import { TypingIndicatorContainer } from "./StyledTypingIndicator";
import { useMessagesContext } from "../MessagesContainer/MessageProvider";
import { SrOnly } from "../SrOnly/SrOnly";
import { EventTypes } from "../../types";

const TypingIndicator: FC<{ key: string; lastEvent: any }> = ({
  key,
  lastEvent,
}) => {
  const [displayTypingTimeout, setDisplayTypingTimeout] = React.useState(false);
  const { sender } = useMessagesContext();

  useEffect(() => {
    const isTypingEvent = lastEvent && lastEvent[0]?.type === EventTypes.TYPING;
    if (isTypingEvent) {
      if (!displayTypingTimeout) {
        setDisplayTypingTimeout(true);
      }
      const timeoutId = setTimeout(() => {
        setDisplayTypingTimeout(false);
      }, 4000);

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setDisplayTypingTimeout(false);
      return;
    }
  }, [lastEvent]);

  if (displayTypingTimeout) {
    return (
      <MessageRow
        key={key}
        isTypingIndicator={true}
        content={
          <TypingIndicatorContainer>
            <SrOnly>{`The ${sender} is typing`}</SrOnly>
            <ItemOne />
            <ItemTwo />
            <ItemThree />
          </TypingIndicatorContainer>
        }
      />
    );
  } else {
    return null;
  }
};

export default TypingIndicator;
