var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SWRConfig } from "swr";
import { ClientSettingsProvider, MsgBubbleIcon, PopupButton, LanguageProvider, ChatLayout } from "wbb-chat-ui";
import { useEffect, useState } from "react";
import useClientInfo from "./hooks/swr/useClientInfo";
import { Chat } from "./view/Chat";
import "./style.css";
import { getPersistBetweenPages, getScreenSettingsParams, queryVarAsBoolean, } from "./helpers";
import { useInitialSession } from "./hooks/swr/useInitialSession";
import { getCompanyInfoProps } from "./view/Chat/utils";
import { useSession } from "./hooks/swr";
import useHeaderInfo from "./hooks/swr/useHeaderInfo";
import { BrowserEventService, EventName } from "./api/BrowserEventService/BrowserEventService";
import { StyleSheetManager } from "styled-components";
function App(_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var mainDiv = _a.mainDiv, styleSlot = _a.styleSlot;
    var initialSession = useInitialSession();
    var _j = useClientInfo(initialSession), clientInfo = _j.clientInfo, error = _j.error, isLoading = _j.isLoading;
    var _k = useState(false), open = _k[0], setOpen = _k[1];
    var pluginStyle = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle;
    var persistBetweenPages = getPersistBetweenPages(pluginStyle === null || pluginStyle === void 0 ? void 0 : pluginStyle.persistBetweenPages, (_b = window.logicDialogParams) === null || _b === void 0 ? void 0 : _b.persistBetweenPages);
    var sessionPayload = __assign(__assign({}, initialSession), { persistBetweenPages: persistBetweenPages });
    var _l = useSession(sessionPayload, Boolean(clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.companyName)), session = _l.session, isValidating = _l.isValidating;
    var headerLoading = useHeaderInfo(session).isLoading;
    var language = initialSession.language;
    var screenSettingsParams = getScreenSettingsParams(clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle, window.logicDialogParams);
    var showDebugInformation = queryVarAsBoolean("showDebugInformation", false);
    var _m = getCompanyInfoProps(language, clientInfo), buttonText = _m.buttonText, botIcon = _m.botIcon;
    function handleToggle() {
        BrowserEventService.createEvent(EventName.TOGGLE_CHAT, {
            open: !open,
            userId: session === null || session === void 0 ? void 0 : session.userId,
            language: session === null || session === void 0 ? void 0 : session.language,
            domainHref: window.location.href,
            client: session === null || session === void 0 ? void 0 : session.client,
        });
        setOpen(!open);
    }
    BrowserEventService.subscribeToEvent(EventName.OPEN_CHAT, function (event) {
        if (event.detail.start) {
            window.logicDialogParams.startBlock = event.detail.start;
            initialSession.startBlock = event.detail.start;
        }
        BrowserEventService.createEvent(EventName.TOGGLE_CHAT, {
            open: true,
            userId: session === null || session === void 0 ? void 0 : session.userId,
            language: session === null || session === void 0 ? void 0 : session.language,
            domainHref: window.location.href,
            client: session === null || session === void 0 ? void 0 : session.client,
        });
        setOpen(true);
    });
    useEffect(function () {
        var _a, _b;
        if (((_a = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle) === null || _a === void 0 ? void 0 : _a.zindex) && mainDiv) {
            mainDiv === null || mainDiv === void 0 ? void 0 : mainDiv.style.setProperty('z-index', ((_b = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle) === null || _b === void 0 ? void 0 : _b.zindex) + '');
        }
    }, [mainDiv, (_c = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle) === null || _c === void 0 ? void 0 : _c.zindex]);
    if (((_d = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle) === null || _d === void 0 ? void 0 : _d.disablePlugin) && !(initialSession === null || initialSession === void 0 ? void 0 : initialSession.testingMode)) {
        return _jsx("div", {});
    }
    if (isLoading || headerLoading) {
        return _jsx("div", {});
    }
    if (error) {
        console.log("There was a problem with loading plugin => ", error);
        return _jsx("div", {});
    }
    return (_jsx(StyleSheetManager, { target: styleSlot, children: _jsxs(_Fragment, { children: [_jsx("link", { rel: "stylesheet", type: "text/css", charSet: "UTF-8", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css" }), _jsx("link", { rel: "stylesheet", type: "text/css", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css" }), _jsx(ChatLayout, { apiStyle: (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle) || {}, windowStyle: window.logicDialogParams, children: _jsx(SWRConfig, { value: {
                            revalidateIfStale: false,
                            revalidateOnFocus: false,
                        }, children: _jsx(LanguageProvider, { lang: language, children: _jsx(ClientSettingsProvider, __assign({}, screenSettingsParams, { showDebugInformation: showDebugInformation, open: open, children: _jsxs(_Fragment, { children: [isValidating ? null
                                            : (_jsx(Chat, { botIcon: botIcon || "", initialSession: initialSession, open: open, typingIndicator: (_e = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle) === null || _e === void 0 ? void 0 : _e.typingIndicator, chatSessionSettings: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.chatSession, onToggle: handleToggle, companyName: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.companyName, closeChat: function () {
                                                    setOpen(false);
                                                } })), !open && (_jsx(PopupButton, { text: buttonText, open: open, onToggle: handleToggle, disabled: isValidating, startIcon: _jsx(MsgBubbleIcon, {}), customIcon: ((_f = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle) === null || _f === void 0 ? void 0 : _f.customButtonIcon)
                                                ? {
                                                    src: (_g = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle) === null || _g === void 0 ? void 0 : _g.customButtonIcon,
                                                    outside: !!((_h = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.pluginStyle) === null || _h === void 0 ? void 0 : _h.customIconOutsideButton),
                                                }
                                                : undefined }))] }) })) }) }) })] }) }));
}
export default App;
