import React from "react";
import { ButtonsDiv, StyledAddMediaContainer } from "./StyledMediaPanel";

import { EmojiClickData } from "emoji-picker-react";
import ToggleBackButton from "./ToggleBackButton";
import { HIDE_MEDIA_ROW, InputRow, MEDIA_ROW } from "../styledTextContainer";
import { MediaPanelProps } from "./MediaPanel.types";
import { useTextContainerContext } from "../TextContainerProvider";

const Dictaphone = React.lazy(() => import("../../Dictaphone/Dictaphone"))
const EmojiPicker = React.lazy(() => import("../../EmojiPicker/EmojiPicker"))
const UploadFile = React.lazy(() => import("../../UploadFile/UploadFile"))
const WhisperDictaphone = React.lazy(() => import("../../Dictaphone/WhisperDictaphone"))

function MediaPanel({ onToggle, setValue, mediaNodeRef }: MediaPanelProps) {
    const { enableSpeechToText, enableEmojis, enableUploadFile, enableWhisperSTT } = useTextContainerContext()

    function handleEmojiClick(emojiData: EmojiClickData) {
        setValue(
            (inputValue: string) =>
                inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji)
        );
        onToggle()
    }

    /* media panel clip-path has conflict with emoji picker clip-path
    we need to remove clip-path from media panel */
    function onOpenEmojisPicker() {
        if (mediaNodeRef?.current) mediaNodeRef.current.className = MEDIA_ROW
    }

    return (
        <React.Suspense fallback={< ></>}>
            <InputRow ref={mediaNodeRef} className={HIDE_MEDIA_ROW}>
                <StyledAddMediaContainer>
                    <ToggleBackButton onClick={onToggle} />
                    <ButtonsDiv>
                        {enableEmojis && <EmojiPicker onOpen={onOpenEmojisPicker} onEmojiClick={handleEmojiClick} />}
                        {enableUploadFile && <UploadFile />}
                        {(enableSpeechToText && !enableWhisperSTT) && <Dictaphone setValue={setValue} onToggle={onToggle} enableSpeechToText={enableSpeechToText} />}
                        {(enableSpeechToText && enableWhisperSTT) && <WhisperDictaphone setValue={setValue} onToggle={onToggle} />}
                    </ButtonsDiv>
                </StyledAddMediaContainer>
            </InputRow>
        </React.Suspense>
    )
}

export default MediaPanel
