import {
  DeliveryInfo,
  FormattedAttachmentMsg,
  FormattedBotMsg,
  FormattedComplexMsg,
  FormattedMsgEvent,
  FormattedTextMsg,
} from "../types/events/eventsTypes";
import {
  AttachmentsType,
  BlockTypes,
  MsgTypes,
  ProceededBlock,
  Senders,
  WbbEvent,
  WbbEventMessage,
  mediaKeys,
  LOCATION_FORM_FIELD_TYPE,
} from "../types/events";
import { Languages } from "../types";

/**
 * Format Message event for UI presentation
 */
export const fromMsgEvent = (
  event: WbbEvent,
  operator: Senders
): FormattedMsgEvent | FormattedMsgEvent[] | undefined => {
  const msgType = event?.message?.type;
  const parsedText = event?.message?.parsedText || null;
  const formattedMessage = {
    [MsgTypes.TEXT]: formatTextMessage(event, operator),
    [MsgTypes.CHOICE]: formatChoiceMessage(event, operator),
    [MsgTypes.ATTACHMENT]: formatAttachmentMessage(event, operator),
    [MsgTypes.BOT_MSG]: formatFromBotOrAgent(event, Senders.BOT, parsedText),
    [MsgTypes.AGENT_MSG]: formatFromBotOrAgent(
      event,
      Senders.AGENT,
      parsedText
    ),
    [MsgTypes.COMPLEX]: formatComplexMessage(event, operator),
    [MsgTypes.SELECT]: undefined,
    [MsgTypes.URL_TRACK]: undefined,
    [MsgTypes.INFO]: undefined,
    [MsgTypes.TYPING]: undefined,
    [MsgTypes.VARIABLE]: undefined,
    [MsgTypes.INTERVENTION_INFO]: undefined,
    [MsgTypes.LANGUAGE]: undefined,
  }[msgType];
  return formattedMessage;
};

const formatComplexMessage = (
  event: WbbEvent,
  operator: Senders
): FormattedComplexMsg => {
  return {
    type: MsgTypes.COMPLEX,
    deliveryInfo: getDeliveryInfo(event, operator),
    block: {
      type: MsgTypes.COMPLEX,
      content: event.message.blocks,
    },
  };
};

const formatTextMessage = (
  event: WbbEvent,
  operator: Senders
): FormattedTextMsg => {
  return {
    type: MsgTypes.TEXT,
    deliveryInfo: getDeliveryInfo(event, operator),
    block: {
      type: event.message.type,
      content: {
        text: event?.message?.text?.text,
      },
    },
  };
};

const formatChoiceMessage = (
  event: WbbEvent,
  operator: Senders
): FormattedMsgEvent => {
  return {
    type: MsgTypes.TEXT,
    deliveryInfo: getDeliveryInfo(event, operator),
    block: {
      type: MsgTypes.TEXT,
      content: {
        text: event?.message?.choice?.selectedTitle,
      },
    },
  };
};

const formatAttachmentMessage = (
  event: WbbEvent,
  operator: Senders
): FormattedAttachmentMsg => {
  const attachmentType: AttachmentsType = attachmentTypeHelper(event.message);
  return {
    type: MsgTypes.ATTACHMENT,
    deliveryInfo: getDeliveryInfo(event, operator),
    block: {
      type: MsgTypes.ATTACHMENT,
      content: {
        [attachmentType]: attachmentType
          ? event?.message?.[attachmentType]
          : "",
        text: event?.message?.text?.text,
      },
      fileName: event?.message?.fileName,
    },
  };
};

const getBotOrAgentMsgType = (block?: ProceededBlock): MsgTypes => {
  if (block?.type === "media") return MsgTypes.ATTACHMENT;
  if (block && isSelectBlock(block)) return MsgTypes.SELECT;
  if (block && isComplexBlock(block)) return MsgTypes.COMPLEX;
  if (block && isVariableBlock(block)) return MsgTypes.VARIABLE;
  if (block && isLocationChoiceBlock(block)) return MsgTypes.SELECT;
  if (block && isFileFormField(block)) return MsgTypes.SELECT;
  return MsgTypes.TEXT;
};

const formatFromBotOrAgent = (
  event: WbbEvent,
  sender: Senders,
  parsedText: string | null
): (FormattedBotMsg | FormattedAttachmentMsg)[] => {
  const lang = event?.message?.selectedLang || "en";
  const deliveryInfo = getAgentOrBotDeliveryInfo(event, sender);
  const formattedMessages: (FormattedBotMsg | FormattedAttachmentMsg)[] = [];
  const preprocessedBlocks = event?.message?.preprocessedBlocks || [];

  for (const block of preprocessedBlocks) {
    const type = getBotOrAgentMsgType(block);

    // Skip blocks of type 'VARIABLE'
    if (!isVariableBlock(block)) {
      let preprocessedBlock;
      if (isLocationChoiceBlock(block)) {
        preprocessedBlock = getBlockWithLocationChoice(
          block,
          lang as Languages
        );
      } else if (isFileFormField(block)) {
        preprocessedBlock = getBlockWithFileChoice(block, lang as Languages);
      } else {
        preprocessedBlock = block;
      }
      const formattedMessage = {
        id: event._id,
        type,
        deliveryInfo,
        parsedText,
        block: preprocessedBlock,
        lang,
        meta: preprocessedBlock.meta,
        feedback: event.feedback,
        icon: event?.agentId && event?.icon ? event.icon : undefined,
        events: block.events,
        language: event.language,
        draft: event?.message?.draft,
        evaluatedBlocks : event?.message?.evaluatedBlocks,
      };
      formattedMessages.push(formattedMessage as any);
    }
  }

  return formattedMessages;
};

const isComplexBlock = (block: ProceededBlock): boolean => {
  const galleryItems =
    block?.content?.items?.[0].image || block?.content?.items?.[0].video;
  return !!galleryItems || block.type === BlockTypes.COMPLEX;
};

const isVariableBlock = (block: ProceededBlock): boolean => {
  return block?.type === BlockTypes.VARIABLE;
};

const isLocationChoiceBlock = (block: ProceededBlock): boolean => {
  return (
    block?.formFieldCallback?.args?.formModelObj?.fieldType ===
    LOCATION_FORM_FIELD_TYPE
  );
};

const isSelectBlock = (block: ProceededBlock): boolean => {
  const choices = block?.content?.items?.[0];
  const hasChoices = choices && choices.choices.length > 0;
  return Boolean(
    block.type === BlockTypes.SELECT &&
      (block?.meta?.choiceType === "qreply" ||
        block?.meta?.choiceType === "question") &&
      hasChoices
  );
};

const isFileFormField = (block: ProceededBlock): boolean => {
  return (
    block?.formFieldCallback?.args?.formModelObj?.fieldType === "input-file"
  );
};

const attachmentTypeHelper = (message: WbbEventMessage): any => {
  if (message?.url) return AttachmentsType.url;

  const mediaBlock = message.blocks?.find((block) => block?.type === "media");
  if (message?.image?.length || mediaBlock) return AttachmentsType.image;

  let otherKey = "";
  mediaKeys.forEach(function (key) {
    if (message[key]) {
      if (message[key]!.length > 0) {
        otherKey = key;
      }
      return;
    }
    return;
  });
  return otherKey;
};

/**
 * Prepare information about event delivery status
 */
const getDeliveryInfo = (
  event: WbbEvent,
  operator?: Senders,
  sender = Senders.USER
): DeliveryInfo => {
  return {
    timestamp: event.created,
    sender,
    sent: sender === operator,
    delivered: event?.channelIn
      ? event.message.delivered[event.channelIn]
      : null,
    read: event?.channelIn ? event.message.read[event?.channelIn] : null,
  };
};

const getAgentOrBotDeliveryInfo = (
  event: WbbEvent,
  sender = Senders.USER
): DeliveryInfo => {
  // Sort the sent dates into ascending order
  const sentDates = Object.values(event.message.sent).sort();
  return {
    timestamp: sentDates[sentDates.length - 1] || event.created,
    sender: sender,
    sent: Senders.AGENT === sender,
    delivered: event?.channelsOut?.[0]
      ? event?.message?.delivered?.[event.channelsOut[0]]
      : null,
    read: event?.channelsOut?.[0]
      ? event?.message?.read?.[event.channelsOut[0]]
      : null,
  };
};

const getBlockWithLocationChoice = (block: ProceededBlock, lang: Languages) => {
  const selectedTitle =
    block.formFieldCallback.args.formModelObj.additionalModalData
      ?.openWindowButtonPrompt?.[lang] || "Open Map";
  return {
    ...block,
    type: BlockTypes.SELECT,
    content: {
      ...block.content,
      items: [
        {
          choices: [
            {
              action: {
                type: "map",
              },
              selectedTitle: selectedTitle,
              title: selectedTitle,
              metadata: {
                ...block.formFieldCallback.args.formModelObj
                  .additionalModalData,
                lang,
              },
            },
          ],
        },
      ],
    },
  };
};

const getBlockWithFileChoice = (block: ProceededBlock, lang: Languages) => {
  const selectedTitle =
    block.formFieldCallback.args.formModelObj.additionalModalData
      ?.openWindowButtonPrompt?.[lang];
  const { fileTypes } =
    block.formFieldCallback.args.formModelObj.additionalModalData;
  // if(onlyURL && onlyURL===true){
  //   return block;
  // }
  const fileTypeMappings: {
    [key: string]: string;
  } = {
    "application/pdf": "PDF",
    "application/msword": "WORD",
    "image/png": "PNG",
    "image/jpeg": "JPEG",
  };
  if (fileTypes && fileTypes.length) {
    block.formFieldCallback.args.formModelObj.additionalModalData.fileTypes =
      fileTypes.map((el: string) => fileTypeMappings[el] || el);
  }

  const previousChoices = block?.content?.items?.length
    ? block?.content?.items[0].choices
    : [];

  return {
    ...block,
    type: BlockTypes.SELECT,
    content: {
      ...block.content,
      title: selectedTitle,
      items: [
        {
          choices: [
            {
              action: {
                type: "file",
              },
              selectedTitle: selectedTitle,
              title: selectedTitle,
              metadata: {
                ...block.formFieldCallback.args.formModelObj
                  .additionalModalData,
                formId: block.formFieldCallback.args.formModelObj._id,
                lang,
              },
            },
            ...previousChoices,
          ],
        },
      ],
    },
  };
};
