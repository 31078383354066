import { APiPluginStyle } from "../../components/ThemeProvider/ThemeProvider.types";
import { DEFAULT_NEUTRAL_COLORS } from "./default-theme";
import { checkBackgroundTextContrast } from "./general";

export const getNeutralColor = (apiSettings: APiPluginStyle, windowSettings: any) => {
    const windowMainColour = windowSettings?.neutralMain;
    const windowMainTextColour = windowSettings?.neutralContrast;
    const windowOutlineColour = windowSettings?.neutralOutline;

    const apiMainColour = apiSettings?.neutralMain;
    const apiMainTextColour = apiSettings?.neutralContrast;
    const apiOutlineColour = apiSettings?.neutralOutline;
    
    const main = windowMainColour ||  DEFAULT_NEUTRAL_COLORS.main;
    const outline = windowMainTextColour || DEFAULT_NEUTRAL_COLORS.outline;
    const mainContrast = windowOutlineColour ||DEFAULT_NEUTRAL_COLORS.mainContrast;
    const textColour = checkBackgroundTextContrast(main, mainContrast) 

    return {
        main: apiMainColour || main,
        outline: apiOutlineColour || outline,
        mainContrast: apiMainTextColour || textColour
    }
}
