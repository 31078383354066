import styled from "styled-components"
import { ButtonColors } from "../../Button/Button.types";
import { getTextStyles } from "../../Button/styleHelper";

export const StyledMenuItem = styled.div`
${({ theme }) => getTextStyles(theme.colors, ButtonColors.primary)}}
text-align: left;
overflow: hidden;
display: flex;
width: 100%;
max-width: 100%;
height: 32px;
max-height: 32px;
flex-direction: row;
justify-content: flex-start;
align-items: center;
cursor: pointer;
& > p {
    margin-left: 8px;
}
`;