import React, { useState } from 'react';
import  { usePopper } from 'react-popper';
import {  TooltipContent, Text } from './StyledTooltip';

interface TooltipProps {
    children: React.ReactNode;
    content: React.ReactNode;
}

function Tooltip({ children, content }: TooltipProps) {
    const [hoverRef, setHoverRef] = useState<HTMLDivElement | null>(null);
    const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(hoverRef, tooltipRef, {
        placement: "bottom",
    });

    const showTooltip = () => {
        tooltipRef?.setAttribute('data-show', 'true');
    }

    const hideTooltip = () => {
        tooltipRef?.removeAttribute('data-show')
    }

    return (
        <>
            <div
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
                ref={setHoverRef} aria-describedby="tooltip">
                {children}
            </div>
            <TooltipContent
                id="tooltip"
                role="tooltip"
                ref={setTooltipRef}
                style={styles.popper}
                {...attributes.popper}
            >
                <Text>{content}</Text>
            </TooltipContent>

        </>
    );
};

export default Tooltip;
