
import styled from "styled-components";
import Slider from "react-slick";


export const BackgroundImage = styled.div<{ image: string, fullScreen?: boolean }>`
background: url(${props => props.image}) no-repeat center center;
background-size: cover;
height: ${props => props.fullScreen ? '500px' : '150px'};
width: 100%;
margin: 0;
list-style: none;
padding: 0;
border-radius: 6px 6px 6px 0px;
`;

export const Video = styled.video`
  width: 80%;
  float: left;
  height: auto;
  border-radius: 15px;
`;

export const Title = styled.div`
font-weight: 700;
`;

export const TextWrapper = styled.div`
width: 100%;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.10000000149011612px;
text-align: left;

`;

export const StyledSlider = styled(Slider)`
border-radius: 6px;
border: 1px solid ${({ theme }) => theme.colors.primary.main};
.slick-list > div > div.slick-slide.slick-active.slick-current > div {
  padding: 8px;
}
.slick-arrow.slick-next {
  &:before {
    content: "";
  }
}
.slick-arrow.slick-prev {
  &:before {
    content: "";
  }
}
`;
export const SliderItemWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 8px;
`

export const SliderButtonWrapper = styled.div`
margin-top: 8px;
gap: 8px;
display: flex;
flex-direction: column;
`

export const ContentWrapper = styled.div`
border-radius :6px 6px 6px 0px;
width: 100%;
`;
