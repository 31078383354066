import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExpand } from "@fortawesome/free-solid-svg-icons"
import { HeaderButtonWrapper, ControlsButtons } from "../IconButton/IconButtonOnPrimary"
import { SrOnly } from '../SrOnly/SrOnly'

export function ExpandScreen({ onClick }: { onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }) {
    
    return (
        <HeaderButtonWrapper>
            <SrOnly role="status">Chat window expanded to the full screen</SrOnly>
            <ControlsButtons onClick={onClick} >
            <FontAwesomeIcon icon={faExpand} title='Expand the chat widget to the entire screen.'/>
            </ControlsButtons>
        </HeaderButtonWrapper>
    )
}

