export const DEFAULT_PRIMARY_COLORS = {
    main: '#0099D7',
    mainContrast: '#ffffff',
    container: '#c8e6ff',
    containerContrast: '#001E2E',
  }

export  const DEFAULT_SECONDARY_COLORS =  {
    main: '#435061',
    mainContrast: '#C7CBCF',
    container: '#A1A8B0',
    containerContrast: '#2F3234',
}


export  const DEFAULT_TERTIARY_COLORS  =  {
  main: '#132C36',
  mainContrast: '#ffff',
  container: '#F3F6FC',
  containerContrast: '#49494B',
}

 export const DEFAULT_ERROR_COLORS = {
    main: '#B3261E',
    mainContrast: '#FFFFFF',
    container: '#F9DEDC',
    containerContrast: '#410E0B',
  }

 export const DEFAULT_BACKGROUND_COLORS = {
    main: '#ffff',
    mainContrast: '#000E08',
  }

  export const DEFAULT_NEUTRAL_COLORS = {
    outline: '#F3F6F6',
    main: '#F3F6F6',
    mainContrast: '#797C7B'
  }

  export const DEFAULT_FONTS = ['sans-serif', 'Roboto']

  export const DEFAULT_ICON_SIZES = {
    small: '16px',
    medium: '16px',
    large: '48px',
  }

  export const DEFAULT_FONT_SIZES = {
    small: '12px',
    medium: '14px',
    large: '16px',
    popupSize : { 
      small: '14px',
      medium: '18px',
      large: '22px'
    }
  }

  export const DEFAULT_WIDGET_SIZE = {
    width: '400px',
    height: '800px',
  }

  export const DEFAULT_ADDITIONAL_STYLES = {
    displayAvatar: true,
    bordersRadius: {
      button: '12px', //12px
      iconButton: '50%',
      chatContainer: '12px 12px 0px 12px',
      leftSideChatContainer: '12px 12px 12px 0px',
      main: '12px', 
      menu: '8px'
    },
    width: '430px',
    elevation: 3,
    zIndex: 2000
  }

  export const DEFAULT_THEME = {
    colors: {
      primary: DEFAULT_PRIMARY_COLORS,
      secondary: DEFAULT_SECONDARY_COLORS,
      tertiary: DEFAULT_TERTIARY_COLORS,
      error: DEFAULT_ERROR_COLORS,
      background: DEFAULT_BACKGROUND_COLORS,
      neutral: DEFAULT_NEUTRAL_COLORS
  },
  fonts: DEFAULT_FONTS,
  fontSizes: DEFAULT_FONT_SIZES,
  iconSizes: DEFAULT_ICON_SIZES,
  additionalStyles: DEFAULT_ADDITIONAL_STYLES
};
