import React, { ReactNode } from "react";
import { PreprocessedBlockContent } from "../../../types/events/Block";
import { useMessagesContext } from "../MessageProvider";
import Avatar from "../../Avatar/Avatar";
import { ListItem } from "./ListItem";
import { ListWrapper } from "./StyledList";
import CustomEventsInfo from "../InfoRow/CustomEventsInfo";
import { ComplexWrapper } from "../Gallery/GalleryPreview";
import { SrOnly } from "../../SrOnly/SrOnly";
import { useClientSettings } from "../../ClientSettingsProvider/ClientSettingsProvider";
import { AgentActions, AgentActionsWrapper, getJustification } from "../AgentActions/AgentActions";

interface Props {
  content: PreprocessedBlockContent;
  onSend: (choice: any) => void;
  msgFooter: ReactNode;
}

export const ListPreview: React.FC<Props> = ({ content, onSend, msgFooter }) => {
  const { botAvatar, showAvatar, sender, onEditResponse, operator } = useMessagesContext();
  const { logicdialogPreview, showDebugInformation} = useClientSettings()

  return (
    <>
        <ComplexWrapper>
          <div>
            <Avatar
              src={botAvatar}
              alt={`Avatar image of the ${sender}`}
              display={showAvatar}
              role="presentation"
              chatAvatar={true}
              crossOrigin={logicdialogPreview ? "anonymous" : undefined}
            />
          </div>
          <SrOnly>Bot presented a list</SrOnly>
          <AgentActionsWrapper  
            justification={ getJustification( showDebugInformation, onEditResponse, sender, operator ) }>
            <ListWrapper>
              {content?.items?.map((item, index) => <ListItem key={index} item={item} onSend={onSend} />)}
              <div>{msgFooter}</div>
            </ListWrapper>
            <AgentActions />
          </AgentActionsWrapper>
        </ComplexWrapper >
      <CustomEventsInfo />
    </>
  )
}
