import { css } from 'styled-components';

import { applyShadow } from "../../inner-utils";
import { ButtonColors } from "./Button.types";
import { Colors } from '../ThemeProvider/ThemeProvider.types';
import { checkBackgroundTextContrast } from '../ThemeProvider/utils';

// Some clients have really light primary color on the white background- what makes text unreadable contrast for outlined buttons
export const getContrastColorToBackground = (color: ButtonColors, background: string, colors: Colors) => {
  if (checkBackgroundTextContrast(background, colors[color].main)) {
    return colors[color].main
  }
  return colors[color].containerContrast
}
// If contrast between background and main primary is to lowe then hover value would be invisible
export const getVisibleHover = (color: ButtonColors, background: string, colors: Colors) => {
  if (checkBackgroundTextContrast(background, colors[color].main)) {
    return colors[color].container
  }
  return colors[color].main
}
export const getOutlinedStyles = (colors: Colors, color: ButtonColors) => css`
  background: ${colors.background.main};
  color: ${getContrastColorToBackground(color, colors.background.main, colors)}; 
  border-color: ${colors[color].main};
  &:is(:hover, :focus-visible, :active){
    background: ${getVisibleHover(color, colors.background.main, colors)};
   }
`;

export const getTextStyles = (colors: Colors, color: ButtonColors) => css`
  background: transparent;
  color: ${colors[color].main};
  border-color: transparent;
  &:is(:hover, :focus-visible, :active){
   ${getTonedStyles(colors, color)}
  }
`;

export const getFilledStyles = (colors: Colors, color: ButtonColors, elevation: number) => css`
  background: ${colors[color].main};
  color: ${colors[color].mainContrast};
  border-color: ${colors[color].main};
  box-shadow: ${applyShadow(elevation)};
  &:is(:hover, :focus-visible, :active){
    ${getTonedStyles(colors, color)}
   }
`;

export const getElevatedStyles = (colors: Colors, color: ButtonColors, elevation: number) => css`
  background: ${colors[color].main};
  color: ${colors[color].mainContrast};
  border-color:  ${colors[color].main};
  box-shadow: ${applyShadow(elevation)};
  &:is(:hover, :focus-visible, :active){
    ${getTonedStyles(colors, color)}
   }
`;

export const getTonedStyles = (colors: Colors, color: ButtonColors) => css`
  background: ${colors[color].container};
  color: ${colors[color].containerContrast};
  border-color: ${colors[color].container};
`;

export const getTonedStatesStyles = (colors: Colors, color: ButtonColors, elevation: number) => css`
  background: ${colors[color].main};
  color: ${colors[color].mainContrast};
  border-color: ${colors[color].main};
  &:is(:hover, :focus-visible, :active){
    box-shadow: ${applyShadow(elevation)};
   }
`;

export const getDisabledStyles = () => css`
  opacity: 0.5;
  pointer-events: none;
`;

export const getIconSize = (size: string) => css`
height: ${size};
width:  ${size};
`

export const getIconContainerSize = (size: string) => css`
height: calc(${size} + 14px);
width:  calc(${size} + 14px);
`