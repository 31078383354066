import React from "react";
import { IframeProps } from "./Iframe.types";
import {StyledIframe } from "./StyledIframe";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";

const Iframe: React.FC<IframeProps> = ({ src }) => {
    const {showLeft} = useClientSettings()
    return (
            <StyledIframe
                src={src}
                left={showLeft}
            />
    )
}

export default Iframe;
