export  function processUrl(value: string, pageUrl?: string) {
    if (value.startsWith("mailto:")) {
      return {
        url: value,
        target: "_self",
      };
    }
    const beginsHttp = /^https?:\/\//i.test(value);
    const url= !beginsHttp ? "http://" + value : value;

    // when link points to same domain don't open a new tab instead open in the same tab
    const linkDomain = new URL(url);
    const pageDomain = pageUrl && new URL(pageUrl) || undefined;
    const target = linkDomain.host === pageDomain?.host ? "_parent" : "_blank";

    return { url,target};
  }