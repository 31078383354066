import styled from "styled-components";

export const EmojiPickerWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const EmojiPickerPopper = styled.div<any>`
z-index: ${({ theme }) => theme.additionalStyles.zIndex + 1};
> * {
    &:first-child {
        margin-top: 4px;
        border-radius: 8px 8px 0px 0px;
     }
    &:last-child {
        margin-bottom: 4px;
        border-radius:  0px 0px 8px 8px;
     }
  }
visibility: hidden;
 &[data-show="true"] {
   visibility: visible;
 }
`

export const EmojiPickerInnerWrapper = styled.div`
& > aside  {
border-color: ${({ theme }) => theme.colors.primary.main} !important;
border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main} !important;
box-shadow: -3px 6px 6px 1px #00000040 !important;
& > div {
    transition: none !important;
}
& > div.epr-header.epr_iogimd {
 & > div {
    & > div > input {
        transition: none !important;
    }
    & > div.epr-skin-tones  {
        transition: none !important;
    }
  }
 }
}
`;
