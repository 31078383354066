import { isBefore, startOfDay } from "date-fns"
import { readableTimestampDay } from "../../../utils/time"
import { DateInfoProps } from "./DateInfo.types"

export function getBannerTime({lastEvent, eventTime, isFirstMessage, lang}: DateInfoProps) {
    const lastMessageTime = lastEvent?.deliveryInfo?.timestamp
    const itIsPreviousDate = lastMessageTime  ? isBefore(startOfDay(new Date(lastMessageTime)), new Date(eventTime)) : false
    const showTimeInfo  = isFirstMessage ||  itIsPreviousDate
   return showTimeInfo ? readableTimestampDay(eventTime, lang) : false
}
