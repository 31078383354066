import { createContext } from "react";
import { ClientSettingsContextType } from "./ClientSettingsProvider";

 const ClientSettingsContext = createContext<ClientSettingsContextType>({
    withHeader: true,
    withInput: true,
    fullScreen: false,
    enableFullScreen: false,
    enableEmojis: true,
    enableSpeechToText: true,
    enableWhisperSTT: false,
    showDebugInformation: false, 
    enableUploadFile: false,
    isMobileOrSmallScreen: false,
    enableDownloadChat: false,
})

export default ClientSettingsContext