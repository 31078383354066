import styled from "styled-components";

export const MapWrapper = styled.div`
width: 100%;
border-radius: ${({ theme }) => `0px 0px ${theme.additionalStyles.bordersRadius.chatContainer} ${theme.additionalStyles.bordersRadius.chatContainer}`};
height: calc(100% - 50px);
background-color: ${({ theme }) => theme.colors.background.main};
padding-top: 0px;
 & > div {
    border-radius: 0px 0px 26px 26px;
 }
`

export const Div = styled.div`
height: calc(100% - 19px);
display: flex;
flex-direction: column;
width: 100%;
padding: 10px; 
align-items: center;
gap: 8px;
`