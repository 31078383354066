import styled from "styled-components";

export const AIEnhancementContainer = styled.div<{ disabled?: boolean }>`
display: flex;
gap: 8px;
margin-left: 8px;
& > div {
& > button {
  & > svg {
    color: ${({ theme }) => theme.colors.secondary.mainContrast};
    height: 14px;
    width: auto;
    opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  }
  &:is(:hover, :focus-visible, :active){
    & > svg {
      color: ${({ theme }) => theme.colors.primary.main};
      }
    }
  }
}
`

export const VerticalDivider = styled.div`
border: none;
border-right: 1px solid ${({ theme }) => theme.colors.neutral.outline};
border-color: ${({ theme }) => theme.colors.secondary.mainContrast};
opacity: 0.5;
`