import React from 'react';
import { readableTimestampDay } from "../../utils";
import { StyledMessageProps } from "../MessagesContainer/MessageContainer.types";
import { useMessagesContext } from "../MessagesContainer/MessageProvider"
import styled from 'styled-components';
import { useLanguageContext } from '../LanguageProvider/LanguageProvider';
import { Languages } from '../../types/session/Languages';
import { SrOnly } from '../SrOnly/SrOnly';

const translateText = (text: string, lang: Languages): string => {
    switch (text) {
        case 'Sent':
            if (lang === Languages.WELSH) {
                return 'Anfonwyd';
            } else {
                return 'Sent';
            }
        default:
            return 'Sent'
    }
};

export const DeliveryInformation = () => {
    const { isOperatorMsg, showDeliveryInfo, status } = useMessagesContext()
    const {lang} = useLanguageContext();
    const time = status ? readableTimestampDay(status?.timestamp, lang) : '';
    return (
        status && showDeliveryInfo ? (
            <StatusInfo data-operator={isOperatorMsg}>
                {/** When we using channels it can be also delivered or read- need to be done*/}
                <DeliveryStatus>{translateText('Sent', lang)}</DeliveryStatus>
                <SrOnly>{time}</SrOnly>
                <DeliveryTime>{time}</DeliveryTime>
            </StatusInfo>
        ) : null
    )
}

export const StatusInfo = styled.div<StyledMessageProps>`
    gap: 4px;
    display: flex;
    justify-content: ${(props) => props['data-operator'] ? 'flex-end' : 'flex-start'};
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.neutral.mainContrast};
    font-size: 10px;
    line-height: 10px;
    height: 100%;
    align-items: end;
}
`;

export const DeliveryStatus = styled.span`
font-weight: 600;
`;

export const DeliveryTime = styled.span`
`;