import { MsgTypes } from "wbb-chat-ui";
export var DEFAULT_TEXT_VALUES;
(function (DEFAULT_TEXT_VALUES) {
    DEFAULT_TEXT_VALUES["CHAT_TITLE"] = "Chatting with";
    DEFAULT_TEXT_VALUES["CHAT_BODY"] = "Customer Service";
    DEFAULT_TEXT_VALUES["BUTTON_TEXT"] = "Need Help?";
})(DEFAULT_TEXT_VALUES || (DEFAULT_TEXT_VALUES = {}));
export function getHeaderProps(headerInfo) {
    return {
        subtitle: (headerInfo === null || headerInfo === void 0 ? void 0 : headerInfo.subtitle) || DEFAULT_TEXT_VALUES.CHAT_BODY,
        title: (headerInfo === null || headerInfo === void 0 ? void 0 : headerInfo.title) || DEFAULT_TEXT_VALUES.CHAT_TITLE,
        companyLogo: {
            show: (headerInfo === null || headerInfo === void 0 ? void 0 : headerInfo.logo) ? true : false,
            url: (headerInfo === null || headerInfo === void 0 ? void 0 : headerInfo.logo) || "",
        },
    };
}
export function getCompanyInfoProps(lang, companyInfo) {
    var _a, _b, _c, _d;
    return {
        buttonText: ((_b = (_a = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.messages) === null || _a === void 0 ? void 0 : _a.buttonText) === null || _b === void 0 ? void 0 : _b[lang]) || DEFAULT_TEXT_VALUES.BUTTON_TEXT,
        botIcon: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.icon,
        enableDownloadChat: (_c = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.pluginStyle) === null || _c === void 0 ? void 0 : _c.enableDownloadChat,
        allowFeedback: (_d = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.pluginStyle) === null || _d === void 0 ? void 0 : _d.allowFeedback,
    };
}
// Function to filter events with type "typing"
export function filterOutTyping(arr) {
    return arr.filter(function (item) { return item.type !== MsgTypes.TYPING; });
}
