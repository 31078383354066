import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { parseFile } from "../../../inner-utils/parsers";

interface FilePreviewProps {
    file: any;
    fileName?: string
}

function FilePreview({ file, fileName }: FilePreviewProps) {
    const [href, setHref] = useState<string>('')

    useEffect(() => {
        // need to use heic2any: to convert heic to jpg
        const parseFileAsyncWrapper = async () => {
            setHref(await parseFile(file))
        }
        if(file) parseFileAsyncWrapper()
    }, [file])

    return (
        <Div>
            <FontAwesomeIcon icon={faFile} />
            <Link href={href} target="_blank" role="link">{
                fileName
            }</Link>
        </Div>

    )
}

export default FilePreview;

const Link = styled.a`
    color: inherit;
`;

const Div = styled.div`
    display: flex;
    gap: 8px;

    & > svg { 
        padding-top: 3px;
        height: 1em;
    }
`;