import React, { FC } from 'react';
import { InfoDiv, InfoWrapper, TrackingURL } from './StyledInfoRow';
import { SrOnly } from '../../SrOnly/SrOnly';
import { useClientSettings } from "../../ClientSettingsProvider/ClientSettingsProvider";

export const InfoRow: FC<{ content: string, href?: string }> = ({ content, href }) => {
    const { zoomLevel } = useClientSettings()
    return (
        <InfoWrapper zoomLevel={zoomLevel}>
            <InfoDiv>
                <SrOnly>Additional information about the conversation</SrOnly>
                <h3>{content}</h3> {href && <TrackingURL href={href} target='_blank'>tracking URL</TrackingURL>}
            </InfoDiv>
        </InfoWrapper>
    )
}

