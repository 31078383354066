export enum EventTypes {
  MESSAGE = "message",
  TRACKING = "tracking",
  AGENT_INTERVENTION = "agentIntervention",
  USER_INTERVENTION = "userIntervention",
  INTERVENTION_END = "interventionEnd",
  AGENT_ASSIGNED = "agentAssigned",
  AGENT_UNASSIGNED = "agentUnassigned",
  CLOSE_EXTENSION = "close-extension",
  CONVERSATION_START = "conversation-start",
  TYPING = "typing",
  LANGUAGE = "switch-language"
}

export enum MsgTypes {
  SELECT = "select",
  TEXT = "text",
  CHOICE = "choice",
  ATTACHMENT = "attachments",
  BOT_MSG = "bot-msg",
  AGENT_MSG = "agent-msg",
  COMPLEX = "complex",
  URL_TRACK = "url-track",
  INFO = "info",
  INTERVENTION_INFO="intervention-info",
  TYPING = "typing",
  VARIABLE = "variable",
  LANGUAGE = "switch-language"
}

export enum Senders {
  USER = "user",
  BOT = "bot",
  AGENT = "agent",
}

export enum Channels {
  TWILIO = "twilio",
  JOVO = "jovo",
  AGENT_SOCKET = "agent-socket",
  MESSENGER = "messenger",
  MICROSOFT = "microsoft",
  API = "api",
  EMAIL = "email",
  // This is when a user is talking directly to an agent over the phone avoiding our system
  PHONE = "phone",
  // This is used when there is no channel in and the input event is just created by the system
  NOT_APPLICABLE = "n/a",
  BROWSER_CALL = "browser-call",
  EIGHT_BY_EIGHT = "eight-by-eight",
  GOOGLE_BUSINESS_MESSAGING = "gbm",
  ALEXA = "alexa",
  MESSAGE_BIRD = "message-bird",
  EIGHT_BY_EIGHT_MESSAGING = "eight-by-eight-messaging",
}

export enum SubChannels {
  JOVO_ALEXA = "jovo-alexa",
  JOVO_GOOGLE_ASSISTANT = "jovo-google-assistant",
}

export enum UserStatuses {
  ACTIVE = "active",
  INTERVENTION = "intervention",
}
