import useSWR from "swr";
import { SessionService } from "../../api/sessionService";
import { SwrKeys } from "./keys";
var useSession = function (payload, clientInfoFetched) {
    var _a = useSWR(function () { return (clientInfoFetched ? SwrKeys.SESSION : null); }, function () { return SessionService.getSession(payload); }, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
    }), data = _a.data, error = _a.error, isLoading = _a.isLoading, isValidating = _a.isValidating;
    return {
        session: data,
        error: error,
        isLoading: isLoading,
        isValidating: isValidating,
    };
};
export default useSession;
