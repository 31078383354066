import React, { FC } from "react";
import styled from "styled-components";

export const VideoPreview: FC<any> = ({ video }) => {
    return (
        <Video controls>
            <source src={video} />
        </Video>
    )
}

const Video = styled.video`
    width: 100%;
    float: left;
    height: auto;
    border-radius: 15px;
`;