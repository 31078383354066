import React, { useCallback } from 'react'
import { Div, TRH, TRItem, Table } from '../DebugInfo/StyledRasaDebug';
import { Block } from '../../types';
import { useClientSettings } from '../ClientSettingsProvider/ClientSettingsProvider';
import { ScrollBar } from '../ScrollBar/ScrollBar';
import { faMessagePen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../IconButton";
import { ButtonColors, ButtonSizes } from "../Button/Button.types";

function LLMDebugInfoOverlap({ knowledgeBlocks, onEditResponse }: { knowledgeBlocks: Block[], onEditResponse: (blockId: string) => void}) {
    const { showLeft } = useClientSettings()
 
    const handleEditResponse = useCallback(
        (block: Block) => {
            if(onEditResponse && block._id) { 
                onEditResponse(block._id)
            }
        },
        [onEditResponse],
    )

    return (
        <Div left={showLeft}>
            <ScrollBar width={ "100%" }>
                <p>
                    The following table lists all of the Knowledge responses that were used as a basis on which to generate 
                    the answer shown to the user. If you wish to view the response, click on the icon.
                </p>
                <Table >
                    <TRH>
                        <td>Response Name</td>
                        <td width={"30px"}></td>
                    </TRH>
                    {knowledgeBlocks && knowledgeBlocks?.map((block: Block | undefined, index: number) => {
                        return (
                            <TRItem key={"kb-block-" + index}>
                                <td>{block?.content.title.en}</td>
                                <td width={"30px"}>
                                    <IconButton color={ButtonColors.tertiary} size={ButtonSizes.small} onClick={( ) => {
                                        if ( block ) {  
                                            handleEditResponse (block);
                                        }
                                    }} >
                                        <FontAwesomeIcon icon={faMessagePen} />
                                    </IconButton>
                                </td>
                            </TRItem>
                        )
                    })}
                </Table>
            </ScrollBar>
        </Div>

    )
}

export default LLMDebugInfoOverlap
