import React, { FC } from "react";
import { MenuItemProps } from "../../Header/Header.types";
import { StyledMenuItem } from "./StyledMenuItem";
import { useLanguageContext } from "../../LanguageProvider";
import { Languages } from "../../../types";

export const MenuItem: FC<MenuItemProps> = ({
  choice,
  onClick,
  onClosePopup,
  tabIndex
}) => {
  const { lang } = useLanguageContext();

  const handleClick = (event: any) => {
    if (onClick) {
      event.stopPropagation();
      onClick(choice);
      onClosePopup();
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick(event);
    }
  };
  return (
    <StyledMenuItem onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={tabIndex}>
      <p onClick={handleClick}>
        {choice?.selectedTitle ||
          (typeof choice?.title === "string"
            ? choice?.title
            : choice?.title?.[lang] || choice?.title?.[Languages.ENGLISH])}
      </p>
    </StyledMenuItem>
  );
};
