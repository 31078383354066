import React from 'react'
import { InfoRow } from '../InfoRow'
import { getBannerTime } from './utils'
import { DateInfoProps } from './DateInfo.types'

export default function DateInfo({lastEvent, eventTime, isFirstMessage, lang}: DateInfoProps) {
    const bannerTime = getBannerTime({lastEvent, eventTime: eventTime || new Date(), isFirstMessage, lang})
    return (bannerTime ?
        <InfoRow content={bannerTime} key={`time-key-${bannerTime}`} /> : null
    )
}
