import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../IconButton";
import { useMessagesContext } from "../MessagesContainer/MessageProvider";
import {  useOverlapContext } from "../OverlapProvider/OverlapProvider";
import { OverlapComponentTypes } from "../OverlapProvider/OverlapProvider.types";
import { ButtonColors, ButtonSizes } from "../Button/Button.types";
import { faBrainCircuit } from "@fortawesome/pro-light-svg-icons";

function LLMDebugInfo() {
    const { knowledgeBlocks, onEditResponse } = useMessagesContext()
    const { onOpen } = useOverlapContext()

    function handleOpen() {
        onOpen({ componentType: OverlapComponentTypes.Knowledge, props: { knowledgeBlocks, onEditResponse } })
    }
    if (!knowledgeBlocks || knowledgeBlocks.length === 0 || !onEditResponse) {
        return null
    }

    return (
        <IconButton color={ButtonColors.tertiary} size={ButtonSizes.small} onClick={handleOpen} >
            <FontAwesomeIcon icon={faBrainCircuit} />
        </IconButton>
    )
}

export default LLMDebugInfo;