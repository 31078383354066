import React, { FC, PropsWithChildren, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { StyledWidgetContainer } from "./StyledWidgetContainer";
import WidgetContainerWrapper, {
  CLOSE_POPUP_CLASS,
  SHOW_POPUP_CLASS,
} from "./WidgetContainerWrapper";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import { HotkeysProvider } from "react-hotkeys-hook";
import { SrOnly } from "../SrOnly/SrOnly";

const WidgetContainer: FC<PropsWithChildren<{
  open: boolean;
  openOnStart?: boolean;
  refProp?: any;
}>> = ({ refProp, open, openOnStart, children }) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { fullScreen, showLeft, logicdialogPreview } = useClientSettings();

  function hideChat() {
    if (nodeRef.current) {
      nodeRef.current.style.display = "none";
      refProp.current.style.display = "none";
    }
    if (refProp.current) {
      refProp.current.style.display = "none";
    }
  }

  function showChat() {
    if (nodeRef.current) {
      nodeRef.current.style.display = "flex";
    }
    if (refProp.current) {
      refProp.current.style.display = "flex";
      refProp.current.role = 'dialog';
      refProp.current['aria-modal'] = true;
      refProp.current['aria-labelledby'] = 'wbb-chat-widget-title';
    }
  }

  useEffect(() => {
    if (logicdialogPreview) {
      showChat();
    }
  }, [logicdialogPreview]);

  return logicdialogPreview ? (
    <WidgetContainerWrapper
      showLeft={showLeft}
      fullScreen={fullScreen}
      logicdialogPreview={true}
      ref={refProp}
    >
      <StyledWidgetContainer
        ref={nodeRef}
        openOnStart={true}
        left={showLeft}
        fullScreen={fullScreen}
      >
        {children}
      </StyledWidgetContainer>
    </WidgetContainerWrapper>
  ) : (
    <>
    {!open && <SrOnly>The help chat window is closed</SrOnly>}
    <CSSTransition
      in={open}
      timeout={500}
      onEnter={showChat}
      onExited={hideChat}
    >
      <WidgetContainerWrapper
        showLeft={showLeft}
        fullScreen={fullScreen}
        ref={refProp}
      >
        <StyledWidgetContainer
          ref={nodeRef}
          openOnStart={openOnStart}
          className={` ${open ? SHOW_POPUP_CLASS : CLOSE_POPUP_CLASS}`}
          left={showLeft}
          fullScreen={fullScreen}
        >
          <HotkeysProvider >
              {children}
          </HotkeysProvider>
        </StyledWidgetContainer>
      </WidgetContainerWrapper>
    </CSSTransition >
    </>
  );
};

export default WidgetContainer;

