import React, { FC } from 'react';
import {  ButtonProps } from '../Button/Button.types';
import IconButton from '../IconButton';
import styled, { css } from 'styled-components';
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons"
import { faPaperPlane as solidPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface SendButtonProps extends Pick<ButtonProps, 'onClick'> {
  isPortal?: boolean;
  disabled?: boolean;
}

const SendButton: FC<SendButtonProps> = ({isPortal, ...props}) => {
  return (
    <Div isPortal={isPortal}>
      <IconButton {...props}>
        {isPortal ? <FontAwesomeIcon icon={solidPaperPlane} title='Send message button'/> : <FontAwesomeIcon icon={faPaperPlane} title='Send message button'/>}
      </IconButton>
      </Div>
  );
};

export default SendButton;

const Div = styled.div<{ isPortal?: boolean }>`
&> button 
 {
  color:${({ theme, isPortal }) => isPortal ? theme.colors.primary.main : theme.colors.primary.mainContrast};
  background:${({ theme: { colors: { primary, advanced } } }) => advanced?.sendButton || primary.messageGradient};
  ${(props) => props?.isPortal && css`
  background: transparent;
  box-shadow: none;
`};
  border: none;
  > svg {
    height: 16px;
    width: auto;
    padding-right: 3px;
  }
 }
`
