import styled, { css, keyframes } from "styled-components";
import { HEADER_HEIGHT } from "../Header/StyledHeader";

export const SHOW_OVERLAP = 'wbbChatShowOverlap'
export const CLOSE_OVERLAP = 'wbbChatCloseOverlap'

const reveal = keyframes`
from {
  clip-path: inset(0 0 0 100%);
}
to {
  clip-path: inset(0 0 0 0);
}
`

const revealBack = keyframes`
from {

    clip-path: inset(0 0 0 0);
}
to {
  clip-path: inset(0 0 0 100%);
}
`

export const OverlapWrapper = styled.div<{ fullScreen?: boolean, headerHeight?: number }>`
width: 100%;
${({ fullScreen }) => fullScreen && css`
width: 100%;
margin: 0px;
`}
height_broken: calc(100% - ${HEADER_HEIGHT} + 4px);
position: absolute;
top: ${({ headerHeight }) => headerHeight }px;
bottom: 0px;
z-index: ${({ theme }) => theme.additionalStyles.zIndex + 3};
.${SHOW_OVERLAP}{
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: ${reveal} 0.7s forwards;
}
.${CLOSE_OVERLAP} {
  display: flex;
  flex-direction: column; 
  animation: ${revealBack} 0.7s forwards;
}
`

export const EmptyOverlap = styled.div<{ 'data-logicdialog'?: boolean }>`
display: flex;
height: calc(100% - 27px);
background-color: ${({ theme }) => theme.colors.background.main};
border-color: ${({ theme, ...props }) => props['data-logicdialog'] ? 'white' : theme.colors.primary.main};
border-radius: ${({ theme }) => `0px 0px 0px ${theme.additionalStyles.bordersRadius.main} `};
border-top: none;
`

export const OverlapContainer = styled.div`
height: 100%;
display: flex;
flex-direction: column;
& > div.focus-lock {
  height: 100%;
}
`

export const FocusLockDiv = styled.div`
  height: 100%;
`
