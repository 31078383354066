import { ChoiceMetadata, RasaDebugInfo, Block } from "../../types";

export type OverlapProviderProps = {
    onOpen: (componentType: OpenOverlapProps) => void;
    sendMessage: (text: string) => void;
};

export enum OverlapComponentTypes {
    DebugInfo = "DebugInfo",
    Knowledge = "Knowledge",
    Iframe = "Iframe",
    UploadFile = "UploadFile",
    LocationForm = "LocationForm",
}


export type OpenOverlapProps = {
    componentType: OverlapComponentTypes | null;
    props: any;
};

export type IframeType = {
    canGoBack: boolean;
    src: string;
}
export type UploadFileType = {
    onAttachFile:(file: File,  url: string | null | ArrayBuffer, formId?:string) => void;
    onClose?: () => void,
    formId?: string,
    fileTypes?: string[],
}

export type LLMDebugType = { 
    knowledgeBlocks : Block[],
    onEditResponse: (blockId: string) => void
}
export type LocationFormType = ChoiceMetadata

export type OverlapElementProps = { [key in OverlapComponentTypes]?: IframeType | LLMDebugType | RasaDebugInfo | UploadFileType | LocationFormType | null }
