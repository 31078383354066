import styled from "styled-components";

export const StyledIframe = styled.iframe<{left?: boolean}>`
width: 100%;
height: 100%;
margin-left: auto;
margin-right: auto;
display: block;
border: none;
border-radius: ${({ theme,left }) => left?theme.additionalStyles.bordersRadius.leftSideChatContainer :`${theme.additionalStyles.bordersRadius.chatContainer}`};
`;


export const TopBar = styled.div`
width: 100%;
height: 25px;
justify-content: space-between;
display: flex;
flex-direction: row;
align-items: center;
background-color: ${({ theme }) => theme.colors.tertiary.main};
color: ${({ theme }) => theme.colors.tertiary.mainContrast};
& > button  > svg > path {
        fill:  ${({ theme }) => theme.colors.tertiary.mainContrast};
    }
`;

export const IframeTitle = styled.h1`
margin-left: 16px;
color: ${({ theme }) => theme.colors.primary.mainContrast};
font-size: ${({ theme }) => theme.fontSizes.small};
line-height: ${({ theme }) => theme.fontSizes.small};
font-weight: 500;
`;