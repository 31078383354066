import {differenceInCalendarDays, format, Locale} from 'date-fns'
import { cy, enGB }  from 'date-fns/locale';

// Custom locale mapping
const localeMapping: Record<string, Locale> = {
    en: enGB,
    cy: cy,
    // Add more locales as needed
  };

export const readableTimestampDay = (date: string | number | Date, lang: string) => {
    const eventDate = new Date(date);
    const now = new Date();
    const days = differenceInCalendarDays(now, eventDate)

    const time = eventDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
     `${eventDate.toLocaleDateString('en-GB', days < 7 ? {weekday: 'short'} : {day: 'numeric', month: 'short', year: 'numeric'})}, ${time}`;

    const timeFormat = 'HH:mm'
    const dayFormat = 'EEE'
    const dateFormat = 'd MMM yyyy'
    const formatVersion = days < 7 ? `${dayFormat}, ${timeFormat}` : `${dateFormat}, ${timeFormat}`
    return format(eventDate, formatVersion, {locale: localeMapping[lang]});
};
