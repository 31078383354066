import React, { FC } from 'react';
import { StyledMessageRow } from "../StyledMessageContainer";
import Avatar from '../../Avatar';
import { useMessagesContext } from '../MessageProvider';
import { DeliveryInformation } from '../../DeliveryInformation/DeliveryInformation';
import DebugInfo from '../../DebugInfo/DebugInfo';
import { ItemOne, ItemThree, ItemTwo, LoadingContainer, LoadingTxtMessageContainer } from './StyledLoadingItem';
import { MsgBubble } from '../StyledMessageContainer';
import { SrOnly } from '../../SrOnly/SrOnly';
import { useClientSettings } from '../../ClientSettingsProvider/ClientSettingsProvider';

export const LoadingMessageRow: FC = () => {
    const {isOperatorMsg, botAvatar, showAvatar} = useMessagesContext();
    const {logicdialogPreview, zoomLevel} = useClientSettings()

    return (
        <StyledMessageRow data-operator={isOperatorMsg} zoomLevel={zoomLevel}>
            <Avatar 
                src={botAvatar}
                role="presentation"
                display={showAvatar}
                crossOrigin={logicdialogPreview ? "anonymous" : undefined}
            />
            <LoadingTxtMessageContainer>
                <MsgBubble data-operator={isOperatorMsg}>
                    <SrOnly>Loading messages</SrOnly>
                    <LoadingContainer>
                        <ItemOne/>
                        <ItemTwo/>
                        <ItemThree/>
                    </LoadingContainer>    
                </MsgBubble>                
                <DeliveryInformation />
            </LoadingTxtMessageContainer>
            <DebugInfo />
        </StyledMessageRow>
    )
}