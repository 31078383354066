var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var shallowMerge = function (target) {
    var sources = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        sources[_i - 1] = arguments[_i];
    }
    return Object.assign.apply(Object, __spreadArray([target], sources.map(function (source) {
        return Object.entries(source)
            .filter(function (_a) {
            var _key = _a[0], value = _a[1];
            return value !== undefined;
        })
            .reduce(function (obj, _a) {
            var key = _a[0], value = _a[1];
            return (obj[key] = value, obj);
        }, {});
    }), false));
};
