import React, { FC } from 'react';
import Button from '../Button';
import { ButtonSizes, ButtonVariants } from '../Button/Button.types';
import { ChoicesWrapper } from './ChoicesWrapper';
import { ChoicesProps } from './ChoiceMessages.types';
import { useLanguageContext } from '../LanguageProvider';
import { SrOnly } from '../SrOnly/SrOnly';

const ChoiceMessage: FC<ChoicesProps> = ({ choices, onSend }) => {
    const {lang} = useLanguageContext();

    function handleSelectChoice(choice: any) {
        const choiceToSend = choice?.title ?  {...choice, title: {[lang]: choice?.title[lang]? choice?.title[lang]: choice?.title}} : choice
        onSend(choiceToSend)
    }

    return (
        <ChoicesWrapper>
            <SrOnly>You can choose as an answer one of the following choices</SrOnly>
            {choices?.map((choice) => (
                <Button
                    variant={ButtonVariants.outlined}
                    size={ButtonSizes.medium}
                    text={typeof choice?.title === 'object' ? choice?.title?.[lang] :choice?.title || '' }
                    id={choice?.action?.value as string}
                    onClick={() => handleSelectChoice(choice)}
                    key={choice?.action?.value as string}
                    choice={true}
                />
            ))}
        </ChoicesWrapper>
    )
}

export default ChoiceMessage;