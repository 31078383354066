import { TextContainerContextPropsType } from "../TextContainer/TextContainerProvider";

// const PopperType =  Omit<Partial<Options>,"modifiers"> & ReadonlyArray<Modifier<any>
export const getEmojiPickerPositionProps = ({chatContainerRef}: Pick<TextContainerContextPropsType, 'chatContainerRef'>): any => ({
    placement: "bottom",
    modifiers:
        [
            {
                name: 'preventOverflow',
                enabled: true,
                options: {
                    boundary: chatContainerRef, padding: 8
                },
            },
      
        ]
})
