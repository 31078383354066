import React from "react";
import heic2any from "heic2any";
import { faFileArrowUp, faFileWord, faFilePdf, faFileVideo, faFileImage, faFileLines} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button";
import { FileLabelRow, FileTypesIcons, FileUploadContainer, FileUploadDescription, InnerUploadFileDiv, StyledFileUploader, UploadFileWrapper } from "./StyledUploadFile";
import { UploadFileType } from "../OverlapProvider/OverlapProvider.types";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import styled from "styled-components";

const defaultFileTypes = ["JPEG", "PDF", "CSV", "DOCX", "WEBP", "BMP", "HEIC", "PPT", "JPG", "PNG",];

function UploadFileOverlap({ onAttachFile, onClose, formId, fileTypes}: UploadFileType) {
    const {showLeft, zoomLevel} = useClientSettings()

    const handleChange = async (file: Blob) => {
        let fileObj: any = file
        if (fileObj?.type === "image/heic") {
            fileObj = await heic2any({
                blob: fileObj,
                toType: "image/jpeg",
                quality: 0.5,
            });
        }

        const reader = new FileReader()
        if (fileObj) await reader.readAsDataURL(fileObj)
        reader.onload = () => {
            onAttachFile(fileObj, reader.result, formId)
            if(onClose) onClose()
        }
    };

    return (
        <UploadFileWrapper left={showLeft}>
            <InnerUploadFileDiv  left={showLeft} zoomLevel={zoomLevel}>
                <StyledFileUploader
                    handleChange={handleChange}
                    name="file"
                    labe="Upload or drop a file right here"
                    types={fileTypes || defaultFileTypes}
                >
                    <FileUploadContainer>
                        { zoomLevel && zoomLevel <= 2 && ( <FileLabelRow>
                            <FontAwesomeIcon icon={faFileArrowUp} /> <Title>File Upload</Title>
                        </FileLabelRow> ) }
                        <FileUploadDescription>Select the button below, or simply drag and drop any file you wish to share into this window.</FileUploadDescription>
                        { zoomLevel && zoomLevel <= 2 && ( 
                        <FileTypesIcons>
                            <FontAwesomeIcon icon={faFileWord} title="You can upload a word  and doc"/>
                            <FontAwesomeIcon icon={faFilePdf} title="You can upload a pdf file"/>
                            <FontAwesomeIcon icon={faFileVideo} title="You can upload a video file"/>
                            <FontAwesomeIcon icon={faFileImage} title="You can upload a image file" />
                            <FontAwesomeIcon icon={faFileLines} title="You can upload a text file and much more" />
                        </FileTypesIcons> ) }
                        <Button text='Select File'/>
                    </FileUploadContainer>
                </StyledFileUploader>
            </InnerUploadFileDiv>
        </UploadFileWrapper>
    )
}

export default UploadFileOverlap;

const Title = styled.h1`
margin: 0px;
`