import React,{ FC, useState, useRef } from "react"
import { MenuProps } from "./Menu.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons";
import { usePopper } from "react-popper";
import styled from "styled-components"
import { useOutsideClick } from "../../hooks/useOutsideClick"
import { MenuItem } from "./MenuItem"
import { ButtonColors, ButtonVariants } from "../Button/Button.types";
import { TEXT_ROW } from "../TextContainer/styledTextContainer";
import IconButton from "../IconButton";

const Menu: FC<MenuProps> = ({  parentElement, elements, onClickItem}) => {
const popperRef = useRef<any>(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const { styles, attributes } = usePopper<any>(anchorEl, popperRef.current,
        {
            placement: "bottom",
            modifiers:
                [
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundary: parentElement, padding: 8,
                        },
                    },
                    {
                        name: 'offset',
                        options: [10, 20]
                    }
                ]
        },
    );

    const handleClosePopper = () => {
        setAnchorEl(null) 
        popperRef.current.setAttribute( 'data-show' , false );
    }
    const ref = useOutsideClick(handleClosePopper);
 
    const handleOpen = (event: any) => {
        if(parentElement) parentElement.className = TEXT_ROW
        anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
        popperRef.current.setAttribute( 'data-show' , anchorEl ? false : true);
    }
   
    return (
        <Div ref={ref}>
            <IconButton onClick={handleOpen} variant={ButtonVariants.outlined} color={ButtonColors.primary}>
                <FontAwesomeIcon icon={faEllipsisVertical} title="Open the persistent menu button"/>
            </IconButton>

            <Popper
                ref={popperRef}
                style={styles.popper}
                {...attributes.popper}
            >
                {elements.map((element, index) =>
                    <MenuItem key={index + '-menu-item'} choice={element}  tabIndex={index + 1} onClick={onClickItem} onClosePopup={handleClosePopper}/>
                )}
            </Popper>
        </Div>
    )
}

export default Menu;

const Div = styled.div`
padding-right: 8px;
`;

const Popper = styled.div<any>`
width: 150px;
padding: ${({ theme }) => theme.additionalStyles.bordersRadius.menu} 0px;
background-color: ${({ theme }) => theme.colors.background.main};
border: 1px solid ${({ theme }) => theme.colors.primary.main};
border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.chatContainer};
z-index:  ${({ theme }) => theme.additionalStyles.zIndex + 1};
box-shadow: 1px 0 4px hsla(0,0%,9%,.3);
> * {
    &:first-child {
        margin-top: 4px;
        // border-radius: 8px 8px 0px 0px;
     }
    &:last-child {
        margin-bottom: 4px;
        // border-radius:  0px 0px 8px 8px;
     }
  }
visibility: hidden;
 &[data-show="true"] {
   visibility: visible;
 }
`
