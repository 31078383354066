import React, { ReactNode } from "react";
import styled from "styled-components";
import { PreprocessedBlockContent } from "../../../types";
import Avatar from "../../Avatar";
import { GallerySlider } from "../../Slider/Slider";
import { useMessagesContext } from "../MessageProvider";
import CustomEventsInfo from "../InfoRow/CustomEventsInfo";
import { fadeInRight, fadeInLeft } from "../StyledMessageContainer";
import { SrOnly } from "../../SrOnly/SrOnly";
import { useClientSettings } from "../../ClientSettingsProvider/ClientSettingsProvider";
import { AgentActions, AgentActionsWrapper, getJustification } from "../AgentActions/AgentActions";

interface Props {
  content: PreprocessedBlockContent;
  onSend: (choice: any) => void;
  msgFooter: ReactNode;
}

export const GalleryPreview: React.FC<Props> = ({
  content,
  onSend,
  msgFooter,
}) => {
  const { botAvatar, showAvatar, isOperatorMsg, onEditResponse, sender, operator } = useMessagesContext();
  const { logicdialogPreview, showDebugInformation} = useClientSettings()

  const srLabel = "Bot send gallery message";
  return (
    <>
      <ComplexWrapper  data-operator={isOperatorMsg} >
        <div>
          <Avatar
            src={botAvatar}
            alt={`Avatar image of the ${sender}`}
            role="presentation"
            display={showAvatar}
            chatAvatar={true}
            crossOrigin={logicdialogPreview ? "anonymous" : undefined}
          />
        </div>
        <AgentActionsWrapper  
          justification={ getJustification( showDebugInformation, onEditResponse, sender, operator ) }>
          <SliderWrapper>
            <div>
              <SrOnly>{srLabel}</SrOnly>
              <GallerySlider content={content} onSend={onSend} />
            </div>
            <div>{msgFooter}</div>
          </SliderWrapper>
          <AgentActions />
        </AgentActionsWrapper>
      </ComplexWrapper>
      <CustomEventsInfo />
    </>
  );
};

export const ComplexWrapper = styled.div<{ ["data-operator"]?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${(props) => props['data-operator'] ? 'flex-end' : 'flex-start'};
  animation: ${(props) => (props["data-operator"] ? fadeInRight : fadeInLeft)}
    0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    gap: 8px;
`;

const SliderWrapper = styled.div`
  width: 75%;
  margin-left: 8px;
`;
