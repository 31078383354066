import React, { FC, PropsWithChildren, createContext, useContext } from "react";
import { DeliveryInfo, RasaDebugInfo, Senders, Block } from "../../types";

interface MessagesContextProps {
  isOperatorMsg: boolean;
  showAvatar: boolean;
  showDeliveryInfo: boolean;
  botAvatar: string;
  status?: DeliveryInfo;
  debugInfo?: null | RasaDebugInfo;
  typingAvatar: string;
  events?: string[];
  isPortal?: boolean;
  sender: Senders;
  operator?: string;
  blockId?: string;
  knowledgeBlocks?: null | Block[];
  onEditResponse?: (blockId: string) => void;
}
const MessagesContext = createContext<MessagesContextProps>({
  isOperatorMsg: false,
  showAvatar: false,
  showDeliveryInfo: false,
  botAvatar: "",
  status: undefined,
  debugInfo: null,
  typingAvatar: "",
  events: [],
  knowledgeBlocks: null,
  isPortal: false,
  sender: Senders.BOT,
  operator: Senders.USER,
  blockId: "",
  onEditResponse: undefined
});

export const MessagesProvider: FC<PropsWithChildren<MessagesContextProps>> = ({
  isOperatorMsg,
  showAvatar,
  showDeliveryInfo,
  botAvatar,
  status,
  debugInfo,
  children,
  typingAvatar,
  events,
  isPortal,
  sender,
  operator,
  blockId,
  knowledgeBlocks,
  onEditResponse
}) => {
  return (
    <MessagesContext.Provider
      value={{
        isOperatorMsg,
        showAvatar,
        showDeliveryInfo,
        botAvatar,
        debugInfo,
        status,
        typingAvatar,
        events,
        isPortal,
        sender,
        blockId,
        knowledgeBlocks,
        onEditResponse,
        operator
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = () => {
  const context = useContext(MessagesContext);
  return context;
};
