import React, { FC } from "react";

import {
  ButtonProps,
  ButtonSizes,
  ButtonVariants,
  ButtonColors,
} from "./Button.types";
import { StyledButton } from "./StyledButton";

const Button: FC<ButtonProps> = ({
  text,
  onClick,
  disabled = false,
  color = ButtonColors.primary,
  size = ButtonSizes.medium,
  variant = ButtonVariants.elevated,
  startIcon,
  endIcon,
  choice,
  tabIndex=0,
  ...props
}) => {
  return (
    <StyledButton
      type="button"
      onClick={onClick}
      disabled={disabled}
      size={size}
      color={color}
      variant={variant}
      data-start={!!startIcon}
      data-end={!!endIcon}
      choice={!!choice}
      tabIndex={tabIndex}
      {...props}
    >
      {startIcon}
      {text}
      {endIcon}
    </StyledButton>
  );
};

export default Button;
