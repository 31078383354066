import styled from "styled-components";

export const InfoWrapper = styled.div<{ zoomLevel?: number }>`
    padding-top: ${props => props.zoomLevel && props.zoomLevel > 2 ? '4px' : '8px' };
    padding-bottom: ${props => props.zoomLevel && props.zoomLevel > 2 ? '4px' : '8px' };
    display: flex;
    justify-content: center;
    position: relative;
}
`;

export const TrackingURL = styled.a`
  color: ${({ theme }) => theme.colors.primary.main};
  text-decoration: none;
`;

export const InfoDiv = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.mainContrast};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.fontSizes.small};
  z-index: ${({ theme }) => theme.additionalStyles.zIndex + 1};
  background: ${({ theme }) => theme.colors.neutral.outline};
  padding: 8px;
  border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main};
  & > h3 {
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.neutral.mainContrast};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: ${({ theme }) => theme.fontSizes.small};
    font-weight: 500;
  }
`;

export const Divider = styled.hr`
  border: 0.5px solid ${({ theme }) => theme.colors.neutral.outline};
  border-top: none;
  height: 0;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.additionalStyles.zIndex};
`;
