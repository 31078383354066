
import  React, { FC, PropsWithChildren } from 'react';

import { ButtonProps, ButtonSizes, ButtonVariants, ButtonColors, } from '../Button/Button.types';
import { StyledIconButton } from './StyledIconButton';

const IconButton: FC<PropsWithChildren<Omit<ButtonProps, 'text'>>> = ({
  children,
  onClick,
  disabled = false,
  color = ButtonColors.primary,
  size = ButtonSizes.medium,
  variant = ButtonVariants.filled,
  ...props
}) => {
  return (
    <StyledIconButton
      type="button"
      onClick={onClick}
      disabled={disabled}
      size={size}
      color={color}
      variant={variant}
      choice={false}
      {...props}>
      {children}
    </StyledIconButton>
  );
};

export default IconButton;
