var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Languages } from "wbb-chat-ui";
export var useInitialSession = function () {
    var _a, _b;
    var search = window.location.search;
    var params = new URLSearchParams(search);
    // from app settings
    var DEFAULT_CLIENT_STATE = {
        language: ((_a = window.logicDialogParams) === null || _a === void 0 ? void 0 : _a.language) || params.get('language') || Languages.ENGLISH,
        client: params.get('client') || window.logicDialogParams.client,
        domain: window.location.hostname,
        startBlock: ((_b = window.logicDialogParams) === null || _b === void 0 ? void 0 : _b.startBlock) || null,
        testingMode: params.get('testingMode') || null
    };
    return __assign({}, DEFAULT_CLIENT_STATE);
};
