import { Languages } from "../session/Languages";

export const getText = ( key: string, language : string ) => { 
    return MODAL_TEXT[key][language] ? MODAL_TEXT[key][language] : MODAL_TEXT[key][Languages.ENGLISH]
}

export const MODAL_KEYS = { 
    YES : "yes", 
    NO : "no",
    EXIT_CHAT : "exit-chat"
}

const MODAL_TEXT = { 
    [MODAL_KEYS.YES] : { 
        [Languages.ENGLISH] : "Yes",
        [Languages.WELSH] : "Oes"
    },
    [MODAL_KEYS.NO] : { 
        [Languages.ENGLISH] : "No",
        [Languages.WELSH] : "Nac ydw"
    },
    [MODAL_KEYS.EXIT_CHAT] : { 
        [Languages.ENGLISH] : "Are you sure you want to exit?",
        [Languages.WELSH] : "Ydych chi'n siŵr eich bod am adael?"
    }
}