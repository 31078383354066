// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#uniqueIdForWidget {
    font-family: 'Poppins-light', sans-serif;
    position: absolute;
    display: block;
    z-index: 1000;
    font-size: 16px;
    line-height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,eAAe;IACf,iBAAiB;AACrB","sourcesContent":["#uniqueIdForWidget {\n    font-family: 'Poppins-light', sans-serif;\n    position: absolute;\n    display: block;\n    z-index: 1000;\n    font-size: 16px;\n    line-height: 18px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
