import styled from "styled-components";
import { Colors } from "../../ThemeProvider/ThemeProvider.types";
import { checkBackgroundTextContrast } from "../../ThemeProvider/utils";

export const StyledAddMediaContainer = styled.div<{}>`
    display: flex;
    border: none;
    font-family: inherit;
    background: ${({ theme }) => theme.colors.neutral.outline};
    border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main};
    height: 40px;
    align-items: center;
    padding: 0px 6px;
    margin: 6px;
    width: inherit;
`;
export const ButtonsDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: end;
width: 100%;
gap: 8px;
`;

const getContrastColorToBackground = (colors: Colors) => {
    if(checkBackgroundTextContrast(colors.background.main, colors.primary.main)){
      return colors.primary.main
    }
    return colors.primary.containerContrast
  }
  
export const ToggleButtonDiv = styled.div<{ padding?: string }>`
padding: ${({padding}) => { return padding ? padding : "8px" } };
&> button  > svg {
    height: 16px;
    width: auto;
    &:is(:hover, :focus-visible, :active){
        color: ${({theme}) =>getContrastColorToBackground(theme.colors)};
    }
  }
`;