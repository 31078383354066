import React from "react";
import {
  PreprocessedBlockChoice,
  PreprocessedBlockItem,
} from "../../../types/events/Block";
import { ButtonSizes, ButtonVariants } from "../../Button/Button.types";
import FullWidthButton from "../../Button/FullWidthButton";
import { useLanguageContext } from "../../LanguageProvider/LanguageProvider";
import { ComplexMsgBubble } from "../StyledMessageContainer";
import {
  LeftSide,
  ListItemWrapper,
  Text,
  Title,
  ButtonWrapper,
  ImageWrapper,
  Img,
} from "./StyledList";
import { SrOnly } from "../../SrOnly/SrOnly";

interface Props {
  item: PreprocessedBlockItem;
  onSend: any;
}

export const ListItem: React.FC<Props> = ({ item, onSend }) => {
  const { lang } = useLanguageContext();
  const hasChoices = item.choices.length > 0;

  function handleSelectChoice(choice: PreprocessedBlockChoice) {
    onSend(JSON.parse(choice.serialized));
  }
  return (
    <ListItemWrapper>
      <LeftSide>
        <Text>
          <ComplexMsgBubble>
            <Title>
              <SrOnly>Item title:</SrOnly>
              {item.title}
            </Title>
            <h5>
              <SrOnly>Item subtitle:</SrOnly>
              {item.subtitle}
            </h5>
          </ComplexMsgBubble>
        </Text>
        <ButtonWrapper>
        <SrOnly>You can choose one of the following choices</SrOnly>
          {hasChoices &&
            item.choices.map((choice, index) => (
              <FullWidthButton
                key={index}
                variant={ButtonVariants.outlined}
                size={ButtonSizes.medium}
                text={
                  typeof choice?.title === "string"
                    ? choice?.title
                    : choice?.title?.[lang]
                }
                id={choice?.action?.value}
                onClick={() => handleSelectChoice(choice)}
              />
            ))}
        </ButtonWrapper>
      </LeftSide>
      {item.image && (
        <ImageWrapper>
          <Img
            src={item.image}
            alt={`An image of one of the list items with the title "${item.title}", which is a message from the chat bot`}
          />
        </ImageWrapper>
      )}
    </ListItemWrapper>
  );
};
