var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { EventEndpoints } from "./enum";
import axios from "axios";
import { CONFIG } from "../../config";
import { BrowserEventService, EventName } from "../BrowserEventService/BrowserEventService";
var apiClient = axios.create({
    baseURL: CONFIG.userApiBaseUrl + "event",
    withCredentials: true,
});
var TEXT = EventEndpoints.TEXT, CHOICE = EventEndpoints.CHOICE, IMAGE = EventEndpoints.IMAGE, CONVERSATION_START = EventEndpoints.CONVERSATION_START, CONVERSATION_END = EventEndpoints.CONVERSATION_END, MEDIA = EventEndpoints.MEDIA;
var getEvents = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apiClient
                    .get("/", {
                    params: __assign(__assign({}, params), { skipProcessEvents: true }),
                })
                    .then(function (res) { return res.data.data; })
                    .catch(function (error) { })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var postTextEvent = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var domain, client;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                domain = params.domain, client = params.client;
                return [4 /*yield*/, apiClient
                        .post(TEXT, params, {
                        params: {
                            client: client,
                            domain: domain,
                            domainHref: window.location.href,
                        },
                    })
                        .then(function (res) {
                        BrowserEventService.createEvent(EventName.MESSAGE_SENT, {
                            type: "text",
                            language: params.language,
                            text: params.text,
                            userId: params.userId,
                            domainHref: window.location.href,
                            client: client,
                        });
                        return res.data;
                    })
                        .catch(function (error) { })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var postChoiceEvent = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var domain, client;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                domain = params.domain, client = params.client;
                return [4 /*yield*/, apiClient
                        .post(CHOICE, params, {
                        params: {
                            client: client,
                            domain: domain,
                            domainHref: window.location.href,
                        },
                    })
                        .then(function (res) {
                        BrowserEventService.createEvent(EventName.MESSAGE_SENT, {
                            type: "choice",
                            language: params.language,
                            choice: params.choice,
                            userId: params.userId,
                            domainHref: window.location.href,
                            client: client,
                        });
                        return res.data;
                    })
                        .catch(function (error) { })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var postStartConversations = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var domain, client;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if ((_a = window.logicDialogParams) === null || _a === void 0 ? void 0 : _a.variables) {
                    params.variables = (_b = window.logicDialogParams) === null || _b === void 0 ? void 0 : _b.variables;
                }
                domain = params.domain, client = params.client;
                return [4 /*yield*/, apiClient
                        .post(CONVERSATION_START, params, {
                        params: {
                            client: client,
                            domain: domain,
                            domainHref: window.location.href,
                        },
                    })
                        .then(function (res) {
                        BrowserEventService.createEvent(EventName.CONVERSATION_START, {
                            language: params.language,
                            userId: params.userId,
                            newSession: params.newSession,
                            domainHref: window.location.href,
                            client: client,
                        });
                        return res.data;
                    })
                        .catch(function (error) { })];
            case 1: return [2 /*return*/, _c.sent()];
        }
    });
}); };
var postEndConversations = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var domain, client;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                domain = params.domain, client = params.client;
                return [4 /*yield*/, apiClient
                        .post(CONVERSATION_END, params, {
                        params: {
                            client: client,
                            domain: domain,
                            domainHref: window.location.href,
                        },
                    })
                        .then(function (res) {
                        BrowserEventService.createEvent(EventName.CONVERSATION_END, {
                            language: params.language,
                            userId: params.userId,
                            newSession: params.newSession,
                            domainHref: window.location.href,
                            client: client,
                        });
                        return res.data;
                    })
                        .catch(function (error) { })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var postFileEvent = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var blob, rest, formData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                blob = params.blob, rest = __rest(params, ["blob"]);
                formData = new FormData();
                apiClient.interceptors.request.use(function (config) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        config.headers["Content-Type"] = "multipart/form-data";
                        config.params = {
                            client: params.client,
                            domain: params.domain,
                            domainHref: window.location.href,
                        };
                        return [2 /*return*/, config];
                    });
                }); });
                formData.append("upload", blob, blob.name);
                formData.append("body", JSON.stringify(rest));
                return [4 /*yield*/, apiClient
                        .post(IMAGE, formData)
                        .then(function (res) { return res.data; })
                        .catch(function (error) { })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var postFileForm = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var blob, client, domain, userId, rest, formData, config, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                blob = params.blob, client = params.client, domain = params.domain, userId = params.userId, rest = __rest(params, ["blob", "client", "domain", "userId"]);
                formData = new FormData();
                formData.append("upload", blob, blob.name);
                formData.append("body", JSON.stringify(rest));
                config = {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    params: {
                        client: client,
                        domain: domain,
                        userId: userId,
                        domainHref: window.location.href,
                    }
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, apiClient.post(MEDIA, formData, config)];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 3:
                error_1 = _a.sent();
                console.error("Failed to post file:", error_1);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var EventService = {
    getEvents: getEvents,
    postStartConversations: postStartConversations,
    postEndConversations: postEndConversations,
    postTextEvent: postTextEvent,
    postChoiceEvent: postChoiceEvent,
    postFileEvent: postFileEvent,
    postFileForm: postFileForm
};
