import useSWR from "swr";
import { EventService } from "../../api/eventService/EventService";
import { SwrKeys } from "./keys";
import useSession from "./useSession";
var useGetEvents = function (initialSession, open) {
    var session = useSession(initialSession, true).session;
    if (initialSession.startBlock && session) {
        session.startBlock = initialSession.startBlock;
    }
    var _a = useSWR(function () { return ((session === null || session === void 0 ? void 0 : session.userSid) && open ? SwrKeys.EVENTS : null); }, function () { return EventService.getEvents(session); }, {
        revalidateOnMount: true,
    }), data = _a.data, error = _a.error, isLoading = _a.isLoading;
    var startNewChat = (data === null || data === void 0 ? void 0 : data.length) === 0;
    var newChat = useSWR(function () { return (startNewChat ? SwrKeys.START_CHAT : null); }, function () { return EventService.postStartConversations(session); }, {
        revalidateOnMount: true,
    });
    return startNewChat ? newChat : { data: data, error: error, isLoading: isLoading };
};
export default useGetEvents;
