import styled, {keyframes} from "styled-components"

const fadeIn = keyframes`
0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const ChoicesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
    padding-top: 16px;
    animation: ${fadeIn} 1.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`