import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react"
import { Languages } from "../../types/session/Languages"


type LanguageContextType = {
  lang: Languages;
  setLanguage?: Dispatch<SetStateAction<Languages>>
}

const LanguageContext = createContext<LanguageContextType>({
  lang: Languages.ENGLISH,
})

const LanguageProvider = ({ children, lang}: PropsWithChildren<LanguageContextType>) => {
  const [language, setLanguage] = useState(lang)
  return (
    <LanguageContext.Provider value={{
      lang: language,
      setLanguage
      }}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider;

export const useLanguageContext = () => {
  return useContext(LanguageContext);
}