import { Choice } from "../../types/events/Choice";

export interface HeaderProps {
  companyLogo?: {
    show: boolean;
    url: string;
  };
  companyName?: string;
  title?: string;
  subtitle?: string;
  align?: HeaderAlign;
  onToggle: () => void;
  open?: boolean;
  onClickDownloadChat?: () => void;
  onClickEndChat?: () => void;
}

export enum HeaderAlign {
  center = "center",
  left = "left",
  right = "right",
}

export type StyledProps = {
  align?: HeaderAlign;
  fullScreen?: boolean;
};

export interface MenuItemProps {
  choice: PersistentMenuItem;
  onClick?: (item: PersistentMenuItem) => void;
  onClosePopup: () => void;
  tabIndex?: number;
}

export type PersistentMenuItem = Choice;
