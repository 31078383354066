import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
export var useIdle = function (_a) {
    var enableSessionTimeout = _a.enableSessionTimeout, timeout = _a.timeout, confirmationTimeout = _a.confirmationTimeout, endChat = _a.endChat;
    var promptBeforeIdle = confirmationTimeout;
    var timeoutSettings = timeout + promptBeforeIdle;
    var _b = useState(false), openPrompt = _b[0], setOpenPrompt = _b[1];
    var onIdle = function () {
        endChat();
        setOpenPrompt(false);
    };
    var onActive = function () {
        setOpenPrompt(false);
    };
    var onPrompt = function () {
        setOpenPrompt(true);
    };
    var handleStillHere = function () {
        activate();
    };
    var activate = useIdleTimer({
        onIdle: onIdle,
        onActive: onActive,
        onPrompt: onPrompt,
        timeout: timeoutSettings,
        promptBeforeIdle: promptBeforeIdle,
        disabled: !enableSessionTimeout,
    }).activate;
    return { openPrompt: openPrompt, handleStillHere: handleStillHere };
};
