import React from 'react';
import { Languages } from '../../types';
import FullWidthButton from '../Button/FullWidthButton';
import {  SelectChoicesWrapper } from './StyledMessageContainer';
import { useLanguageContext } from '../LanguageProvider';
import { ButtonVariants } from '../Button/Button.types';

interface Props {
  content: any;
  onSend: any;
}

export const QuestionRow: React.FC<Props> = ({ content, onSend }) => {
  const choices = content?.items?.[0]?.choices;
  const { lang } = useLanguageContext()


  function handleSelectChoice(choice: any) {
    const choiceToSend = choice?.title ? { ...choice, title: { [lang]: choice?.title } } : choice
    onSend(choiceToSend)
  }

  const multipleChoices = choices ? choices.length > 1 : false
  return (
          <SelectChoicesWrapper multiple={multipleChoices}>
            {choices && choices.map((choice: any, index: number) => <FullWidthButton
              variant={ButtonVariants.outlined}
              key={choice?.action?.value + index}
              text={typeof choice?.title === 'string' ? choice?.title : choice?.title?.[lang] || choice?.title?.[Languages.ENGLISH]}
              id={choice?.action?.value}
              onClick={() => handleSelectChoice(choice)}
              choice={true}
            />)}
          </SelectChoicesWrapper>
      
  )
}