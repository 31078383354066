import heic2any from "heic2any";

export function parseImage(image: any ) {
    let img = image
    // let img = image.block ? image.block.content.image : image.content.image;
    if (Array.isArray(img)) img = img[0];
    const isDataUrl = img.indexOf('data:image/') !== -1;

    let src;
    if (isDataUrl) {
        const {blob, mime} = dataURItoBlob(img);
        src = URL.createObjectURL(new Blob([blob], {type: mime}));
      } else {
        src = img;
      }
      return src
}

export  async function parseFile(fileObj: any ) {
  let file = fileObj
  if (Array.isArray(file)) file = file[0];
  const isDataUrl = file.indexOf('data:') !== -1;
  if (file.type === "image/heic") {
    file =  await heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 0.5,
    });
  }
  let src;
  if (isDataUrl) {
      const {blob, mime} = dataURItoBlob(file);
      src = URL.createObjectURL(new Blob([blob], {type: mime}));
    } else {
      src = file;
    }
    return src
}

function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);
  
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
  
    // create a view into the buffer
    const ia = new Uint8Array(ab);
  
    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    return {blob: new Blob([ab], {type: mimeString}), mime: mimeString};
  }

