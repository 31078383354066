import React from "react";
import Backdrop from "./Backdrop";
import { ModalContainer, StyledModal } from "./StyledModal";
import FocusLockUI from "react-focus-lock";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";

interface PopperProps {
  content: React.ReactNode;
  titleId: string;
}

const Popper: React.FC<PopperProps> = ({ content, titleId}) => {
  const {  logicdialogPreview, zoomLevel } = useClientSettings()
  return (
      <FocusLockUI
      returnFocus
        disabled={logicdialogPreview}
      >
        <Backdrop role="dialog" aria-labelledby={titleId} aria-modal="true">
          <ModalContainer zoomLevel={zoomLevel}>
            <StyledModal zoomLevel={zoomLevel}>
              {content}
            </StyledModal>
          </ModalContainer>
        </Backdrop>
      </FocusLockUI>
  );
};

export default Popper;
