import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinimize } from "@fortawesome/free-solid-svg-icons"
import { HeaderButtonWrapper, ControlsButtons } from "../IconButton/IconButtonOnPrimary";
import { ButtonSizes } from '../Button/Button.types';

export function ExitFullScreen({ onClick }: { onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }) {
    return (
        <HeaderButtonWrapper>
            <ControlsButtons onClick={onClick} size={ButtonSizes.small} >
                <FontAwesomeIcon icon={faMinimize} title='Return from the full-screen widget view to the chat standard size.' />
            </ControlsButtons>
        </HeaderButtonWrapper>
    )
}

