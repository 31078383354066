import styled, {css} from 'styled-components';

export const Container = styled.div`
width: 100%;
padding: 8px;
`;

export const ToolbarContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
`;


export const InputDiv = styled.div<{ left?: boolean, fullScreen?: boolean, focused?: boolean }>`
padding: 8px;
width: 100%;
display: flex;
gap: 8px;
flex-direction: column;
align-items: center;
background-color: ${({ theme }) => theme.colors.neutral.main};
border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main};
${({ focused }) => focused && css`
    border:1px solid ${({ theme }) => theme.colors.primary.main};
`}
`;

export const StyledTexContainer = styled.div<{ focused?: boolean }>`
width: 100%;
display: flex;
border: none;
font-family: inherit;
padding: 0px 6px 0px 0px;
margin: 6px;
    ${({ focused }) => focused && css`
        border:1px solid ${({ theme }) => theme.colors.primary.main};
    `}
`;


export const TextArea = styled.textarea<{ mobile?: boolean }>`
width: 100%;
height: 38px;
max-height: 300px;
overflow-x: hidden;
overflow-y: auto;
margin: 0;
background: transparent;
resize: none;
border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main};
box-sizing: border-box;
padding: 12px 0px 6px 6px;
border: none;
font-family: inherit;
line-height: 1.5;
background-color: ${({ theme }) => theme.colors.neutral.main};
font-size: 14px;
    &:focus {
    outline: none!important;
}
`;
