import styled from 'styled-components'

/**
 * Style are like in the logicdialog platform 
 */
export const Div = styled.div<{left?: boolean}>`
display: flex;
width: 100%;
border-radius: ${({ theme }) => `0px 0px 0px ${theme.additionalStyles.bordersRadius.main} `};
height: calc(100%);
background-color: #fff;
width: 100%;
`

export const Table = styled.table`
border-spacing: 0px 8px;
width:100%;
padding: 12px;
`;
export const TRH = styled.tr`
color: rgb(140, 143, 149);
font-weight: 400;
font-size: 12px;
`
export const TRItem = styled.tr`
color: rgba(0, 0, 0, 0.87);
border-radius: 12px;
align-items: center;
gap: 16px;
background: linear-gradient(166.81deg, rgba(240, 241, 249, 0.4) -10.7%, rgba(209, 214, 234, 0.4) 924.08%);
margin-bottom: 8px;
& > td {
    padding: 12px 16px;
} 
& td:first-child {
    border-radius: 12px 0px 0px 12px;
  }
  & td:last-child {
    border-radius: 0px 12px 12px 0px
  }
`