import styled, { css } from "styled-components"
import { getDisabledStyles } from "../Button/styleHelper"

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  visibility: hidden;
`

export const StyledCheckbox = styled.div<{ checked: boolean, disabled?: boolean }>`
  display: inline-block;
  width: 14px;
  height: 14px;
  background: ${({ theme, checked }) => checked ? theme.colors.primary.main : 'transparent'};
  border-radius: 3px;
  border: ${({ theme, checked }) => checked ? `1px solid ${theme.colors.primary.main}` : `1px solid ${theme.colors.neutral.mainContrast}`};
  font-weight: 200;
  font-size: 10px;
  line-height: 21px;
  text-align: center;
  ${HiddenCheckbox}:hover + & {
    ${({ disabled }) => !disabled && css`
    border:1px solid ${({ theme }) => theme.colors.primary.main};
    background: ${({ theme }) => theme.colors.primary.container};
    `})}
  }
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
  ${({ disabled }) => disabled && css`${getDisabledStyles()}`}
  `

export const CheckboxLabel = styled.span`
  color: ${({ theme }) => theme.colors.neutral.mainContrast};
  font-size: 12px;
  line-height: 12px;
  padding-left: 8px;
  `;

export const CheckboxWrapper = styled.label``;