export enum Languages {
    ANY = 'any',
    AFRIKAANS = 'af',
    ALBANIAN = 'sq',
    AMHARIC = 'am',
    ARABIC = 'ar',
    ARMENIAN = 'hy',
    ASSAMESE = 'as',
    AYMARA = 'ay',
    AZERBAIJANI = 'az',
    BAMBARA = 'bm',
    BASQUE = 'eu',
    BELARUSIAN = 'be',
    BENGALI = 'bn',
    BHOJPURI = 'bho',
    BOSNIAN = 'bs',
    BULGARIAN = 'bg',
    CATALAN = 'ca',
    CEBUANO = 'ceb',
    CHINESE_SIMPLIFIED = 'zh-CN',
    CHINESE_TRADITIONAL = 'zh-TW',
    CORSICAN = 'co',
    CROATIAN = 'hr',
    CZECH = 'cs',
    DANISH = 'da',
    DHIVEHI = 'dv',
    DOGRI = 'doi',
    DUTCH = 'nl',
    ENGLISH = 'en',
    ESPERANTO = 'eo',
    ESTONIAN = 'et',
    EWE = 'ee',
    FILIPINO = 'fil',
    FINNISH = 'fi',
    FRENCH = 'fr',
    FRISIAN = 'fy',
    GALICIAN = 'gl',
    GEORGIAN = 'ka',
    GERMAN = 'de',
    GREEK = 'el',
    GUARANI = 'gn',
    GUJARATI = 'gu',
    HAITIAN_CREOLE = 'ht',
    HAUSA = 'ha',
    HAWAIIAN = 'haw',
    HEBREW = 'he',
    HINDI = 'hi',
    HMONG = 'hmn',
    HUNGARIAN = 'hu',
    ICELANDIC = 'is',
    IGBO = 'ig',
    ILOCANO = 'ilo',
    INDONESIAN = 'id',
    IRISH = 'ga',
    ITALIAN = 'it',
    JAPANESE = 'ja',
    JAVANESE = 'jv',
    KANNADA = 'kn',
    KAZAKH = 'kk',
    KHMER = 'km',
    KINYARWANDA = 'rw',
    KONKANI = 'gom',
    KOREAN = 'ko',
    KRIO = 'kri',
    KURDISH = 'ku',
    KURDISH_SORANI = 'ckb',
    KYRGYZ = 'ky',
    LAO = 'lo',
    LATIN = 'la',
    LATVIAN = 'lv',
    LINGALA = 'ln',
    LITHUANIAN = 'lt',
    LUGANDA = 'lg',
    LUXEMBOURGISH = 'lb',
    MACEDONIAN = 'mk',
    MAITHILI = 'mai',
    MALAGASY = 'mg',
    MALAY = 'ms',
    MALAYALAM = 'ml',
    MALTESE = 'mt',
    MAORI = 'mi',
    MARATHI = 'mr',
    MEITEILON = 'mni-Mtei',
    MIZO = 'lus',
    MONGOLIAN = 'mn',
    MYANMAR_BURMESE = 'my',
    NEPALI = 'ne',
    NORWEGIAN = 'no',
    NYANJA = 'ny',
    ODIA = 'or',
    OROMO = 'om',
    PASHTO = 'ps',
    PERSIAN = 'fa',
    POLISH = 'pl',
    PORTUGUESE = 'pt',
    PUNJABI = 'pa',
    QUECHUA = 'qu',
    ROMANIAN = 'ro',
    RUSSIAN = 'ru',
    SAMOAN = 'sm',
    SANSKRIT = 'sa',
    SCOTS_GAELIC = 'gd',
    SEPEDI = 'nso',
    SERBIAN = 'sr',
    SESOTHO = 'st',
    SHONA = 'sn',
    SINDHI = 'sd',
    SINHALA = 'si',
    SLOVAK = 'sk',
    SLOVENIAN = 'sl',
    SOMALI = 'so',
    SPANISH = 'es',
    SUNDANESE = 'su',
    SWAHILI = 'sw',
    SWEDISH = 'sv',
    TAGALOG = 'tl',
    TAJIK = 'tg',
    TAMIL = 'ta',
    TATAR = 'tt',
    TELUGU = 'te',
    THAI = 'th',
    TIGRINYA = 'ti',
    TSONGA = 'ts',
    TURKISH = 'tr',
    TURKMEN = 'tk',
    TWI = 'ak',
    UKRAINIAN = 'uk',
    URDU = 'ur',
    UYGHUR = 'ug',
    UZBEK = 'uz',
    VIETNAMESE = 'vi',
    WELSH = 'cy',
    XHOSA = 'xh',
    YIDDISH = 'yi',
    YORUBA = 'yo',
    ZULU = 'zu',
}
