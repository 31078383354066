import styled, { css } from 'styled-components';

import { ButtonVariants, ButtonStyledProps, ButtonColors,  } from '../Button/Button.types';
import { getOutlinedStyles, getTonedStatesStyles, getElevatedStyles, getFilledStyles, getDisabledStyles, getIconSize, getIconContainerSize } from '../Button/styleHelper';
import { Colors } from '../ThemeProvider/ThemeProvider.types';


export const StyledIconButton = styled.button<Required<Omit<ButtonStyledProps, 'data-start' | 'data-end'>>>`
  cursor: pointer;
  display: inline-block;
  display: flex;
  align-items: center;
  padding: 5px;

  // SIZING
  border: 1px solid;
  border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.iconButton};
  ${({ size, theme }) => getIconContainerSize(theme.iconSizes[size])}

  & svg {
    ${({ size, theme }) => getIconSize(theme.iconSizes[size])}
  }

  // VARIANT & COLOR
  ${({ variant, color, theme }) => {
    switch (variant) {
      case ButtonVariants.outlined:
        return getOutlinedStyles(theme.colors, color);
      case ButtonVariants.toned:
        return getTonedStatesStyles(theme.colors, color, theme.additionalStyles.elevation);
      case ButtonVariants.filled:
        return getFilledStyles(theme.colors, color, theme.additionalStyles.elevation);
      case ButtonVariants.elevated:
        return getElevatedStyles(theme.colors, color, theme.additionalStyles.elevation);
      default: // ButtonVariants.text is default
        return getIconButtonTextStyles(theme.colors, color)
    }
  }
  }
  ${props => props.disabled && getDisabledStyles()}
`;

const getIconButtonTextStyles = (colors: Colors, color: ButtonColors) => css`
  background: transparent;
  color: ${colors[color].main};
  border-color: transparent;
  &:is(:hover, :focus-visible, :active){
  color: ${colors[color].containerContrast};
  }
`;
