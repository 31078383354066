import React, { FC, PropsWithChildren
    // , useState 
} from 'react';

import { ThemeProvider } from 'styled-components';
import { PluginStyle } from './ThemeProvider.types';
// import { AdditionalStyles, BackgroundColors, FontSizes, Fonts, MainColors, NeutralColors, PluginStyle, WidgetSize } from './ThemeProvider.types';

export const Theme: FC<PropsWithChildren<{ clientTheme: PluginStyle }>> = ({ children, clientTheme }) => {
    // const { colors } = clientTheme;

    // const [primary, setPrimary] = useState<MainColors>(colors.primary)
    // const [secondary, setSecondary] = useState<MainColors>(colors.secondary)
    // const [tertiary, setTertiary] = useState<MainColors>(colors.tertiary)
    // const [error, setError] = useState<MainColors>(colors.error)
    // const [background, setBackground] = useState<BackgroundColors>(colors.background)
    // const [neutral, setNeutral] = useState<NeutralColors>(colors.neutral)
    // const [fonts, setFonts] = useState<Fonts>(clientTheme.fonts)
    // const [fontSizes, setFontSizes] = useState<FontSizes>(clientTheme.fontSizes)
    // const [widgetSize, setWidgetSize] = useState<WidgetSize>(clientTheme.widgetSize)
    // const [additionalStyles, setAdditionalStyles] = useState<AdditionalStyles>(clientTheme.additionalStyles)

    return <ThemeProvider theme={clientTheme}>{children}</ThemeProvider>;
};
