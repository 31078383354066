import useSWR from "swr";
import { UserService } from "../../api/userService/UserService";
import { SwrKeys } from "./keys";
import useSession from "./useSession";
var usePersistentMenu = function (initialSession, open) {
    var session = useSession(initialSession, true).session;
    var _a = useSWR(function () { return (session === null || session === void 0 ? void 0 : session.userSid) && open ? SwrKeys.PERSISTENT_MENU : null; }, function () { return UserService.getPersistentMenu(session); }), data = _a.data, pMenuError = _a.error, pMenuIsLoading = _a.isLoading;
    return { persistentMenu: data === null || data === void 0 ? void 0 : data.choices, pMenuError: pMenuError, pMenuIsLoading: pMenuIsLoading };
};
export default usePersistentMenu;
