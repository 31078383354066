import React, { FC } from "react";
import { parseImage } from "../../../inner-utils/parsers";
import styled from "styled-components";

export const ImagePreview: FC<any> = ({ image }) => {
    const href = parseImage(image)
    return (
        <a target='_blank' href={href}>
            <Img src={href} alt="Image Block"/>
        </a>
    )
}

const Img = styled.img`
    width: 100%;
    float: left;
    height: auto;
    border-radius: 15px;
`;