import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { IframeTitle, TopBar } from "../Iframe/StyledIframe";
import { ButtonSizes, ButtonVariants } from "../Button/Button.types";
import IconButton from "../IconButton";
import styled from "styled-components";

const TopOverlapBar: React.FC<{ onClick: () => void, titleId: string, disableClose?: boolean; title?: string }> = ({ onClick, disableClose, title, titleId }) => {
    return (
        <TopBar>
            <IframeTitle id={titleId}>{title}</IframeTitle>
            <IconButton$ onClick={onClick} size={ButtonSizes.small} disabled={disableClose} variant={ButtonVariants.text}>
                <FontAwesomeIcon icon={faXmark} title='Close modal' />
            </IconButton$>
        </TopBar>
    )
}

export default TopOverlapBar;

const IconButton$ = styled(IconButton)`
width: 20px;
padding: 0px;
margin: 2px;
margin-right: 8px;
&:is(:hover, :focus-visible, :active){
    background: ${({ theme }) => theme.colors.tertiary.containerContrast};
   }
`;