import React from "react";
import { translatePlaceholderText } from "../TextContainer/InputPanel/utils";
import { useLanguageContext } from "../LanguageProvider";
import { useTextContainerContext } from "../TextContainer/TextContainerProvider";
import { useEffect, useRef, useState } from "react";
import { Senders } from "../../types";
import { TextContainerProps } from "../TextContainer/TextContainer.types";
import SendButton from "../SendButton";
import EnterSettings from "./EnterSettings";
import useAutosizeTextArea from "./useAutosizeTextArea";
import { Container, InputDiv, TextArea, ToolbarContainer } from "./StyledTextContainer";
import styled from "styled-components";
import { AIEnhancementTools } from "./AIEnhancementTools";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import { Tooltip } from "../Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-light-svg-icons";
import IconButton from "../IconButton";
import { ButtonSizes, ButtonVariants } from "../Button/Button.types";
import { useOverlapContext } from "../OverlapProvider/OverlapProvider";
import { OverlapComponentTypes } from "../OverlapProvider/OverlapProvider.types";
import { VerticalDivider } from "./AIEnhancementTools/StyledAIEnhancementTools";

export interface WBBTextContainerProps extends TextContainerProps {
    msgsContainerRef: React.RefObject<HTMLDivElement> | undefined,
    onEnhanceText: (text: string, context: string) => void,
    aiValue?: string;
    aiDisabled?: boolean;
    fileUploadEnabed? : boolean;
    loadingElement?:any;
    initialValue?: string;
    draftEventId?: string;
}

function WBBTextContainer({ onSend, disabled, operatorTyping, msgsContainerRef, onEnhanceText, aiValue, aiDisabled, fileUploadEnabed, loadingElement, initialValue, draftEventId }: WBBTextContainerProps ) {
    const { lang } = useLanguageContext();
    const placeholder = translatePlaceholderText(lang)
    const { textAreaRef, onAttachFile } = useTextContainerContext()
    const { zoomLevel } = useClientSettings()
    const [value, setValue] = React.useState('');
    const [focused, setFocused] = useState(false)
    const [startedTyping, setStartedTyping] = useState(false)
    const [sendOnEnter, setSendOnEnter] = useState(true)
    const ref = useRef<(() => void) | never>();
    const timerRef = useRef< null | ReturnType<typeof setTimeout>>(null);
    const { onOpen } = useOverlapContext()

    const resetAutoSize = useAutosizeTextArea(textAreaRef, msgsContainerRef, value, timerRef, zoomLevel, false)

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    function onCustomerTyping() {
        operatorTyping(Senders.USER);
    }

    useEffect(() => {
        ref.current = onCustomerTyping;
    }, [onCustomerTyping]);

    function handlePaste() {
        textAreaRef?.current?.focus()
    }

    useEffect(() => {
      if(aiValue){
        setValue(aiValue)
        handlePaste()
      }
      if ( initialValue ) { 
        setValue(initialValue);
        handlePaste();
      }
    }, [aiValue, initialValue])
    

    const debouncedCallback = () => {
        const func = () => {
            ref.current?.();
        };
        if (!startedTyping) {
            func();
            setStartedTyping(true);
            setTimeout(() => {
                setStartedTyping(false);
            }, 3000)
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();
        debouncedCallback();
        setValue(event.currentTarget.value);
    }

    function sendHandler() {
        if (!value?.length) return;
        onSend(value, draftEventId)
        setValue('');
        resetAutoSize()
    }
    function handleSend(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        sendHandler()
    }

    function handleKeyDown(event: any) {
        const isEnter = event.key === 'Enter'
        const ctrlEnter = isEnter && event.ctrlKey
        const sendWhenEnter = sendOnEnter && isEnter && !event.shiftKey
        if (sendWhenEnter || ctrlEnter) {
            event.preventDefault();
            sendHandler()
        }
    }

    function handleChangeCheckbox() {
        setSendOnEnter(prev => !prev)
    }

    function handleEnhancement(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        onEnhanceText(value, event.currentTarget.id)
        if(timerRef.current) clearTimeout(timerRef.current);
    }

    function uploadImage() {
        onOpen({ componentType: OverlapComponentTypes.UploadFile, props: {onAttachFile} })
    }

    return (
        <Container>
            <InputDiv focused={focused}>
                <TextArea rows={1} disabled={disabled} ref={textAreaRef} placeholder={placeholder} value={value} onChange={handleChange} onKeyDown={handleKeyDown} onFocus={onFocus} onBlur={onBlur} onPaste={handlePaste}/>
                <EnterSettings checked={sendOnEnter} onChange={handleChangeCheckbox} disabled={disabled} />
                <ToolbarContainer>
                    <ToolsContainer>
                        <AIEnhancementTools
                            disabled={disabled || !value?.length || aiDisabled}
                            onEnhanceText={handleEnhancement}
                        />
                        { fileUploadEnabed && 
                            <>
                                <VerticalDivider />
                                <>
                                    <Tooltip content='Upload an image'>
                                        <IconButton 
                                            variant={ButtonVariants.text} 
                                            size={ButtonSizes.small} 
                                            disabled={false} id='friendly' 
                                            onClick={uploadImage}>
                                            <FontAwesomeIcon icon={faImage} />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            </>
                        }
                    </ToolsContainer>
                    {loadingElement ? loadingElement : <SendButton onClick={handleSend} isPortal={true} disabled={disabled} /> }
                </ToolbarContainer>

            </InputDiv>
        </Container>
    )
}

export default WBBTextContainer;

const ToolsContainer = styled.div`
    display: flex;
    gap: 5px;
`;
