import styled, { css }  from "styled-components";
import { getBorderRadiusForOperator } from "../MessagesContainer/StyledMessageContainer";
import { HEADER_HEIGHT } from "../Header/StyledHeader";

export const StyledModal = styled.div<{
  zoomLevel?: number;
}>`
  z-index: ${({ theme }) => theme.additionalStyles.zIndex + 5};
  position: fixed;
  background: ${({ theme }) => theme.colors.background.main};
  color: ${({ theme }) => theme.colors.background.mainContrast};
  border-radius: ${({ theme }) =>
    getBorderRadiusForOperator(
      theme.additionalStyles.bordersRadius.main,
      false
    )};
  ${(props) => { 
    return props.zoomLevel && props.zoomLevel > 2 ? css`width: calc(100% - 40px);
    height: calc(100% - 40px);
    left: 20px;` : '';
  }}
`;

export const ModalContainer = styled.div<{
  zoomLevel?: number;
}>`
  position: absolute;
  top: ${HEADER_HEIGHT};
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  ${(props) => { 
    return props.zoomLevel && props.zoomLevel > 2 ? css`top: 20px;
    left: 10px;` : ''
  }}
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  text-align: start;
  font-family: "Poppins-light", sans-serif;
  height: calc(100% - 40px);
  justify-content: space-between;
`;

export const ModalTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0px;
`;

export const ModalText = styled.span`
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;
