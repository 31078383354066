import 'regenerator-runtime/runtime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { ButtonColors } from '../Button/Button.types';
import { faMicrophone } from "@fortawesome/pro-light-svg-icons";
import { faMicrophone as faSolidMicrophone } from "@fortawesome/free-solid-svg-icons";
import IconButton from '../IconButton';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { useTextContainerContext } from '../TextContainer/TextContainerProvider';

interface Props {
  setValue: (value: string) => void
  onToggle: () => void;
}

const WhisperDictaphone: React.FC<Props> = ({ setValue, onToggle }) => {
  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording
  } = useAudioRecorder();
  const {postTranscription, client} = useTextContainerContext();
  const handleOnClick = () => {
    isRecording ? (stopRecording(), onToggle()) : startRecording();
  }

  useEffect(() => {
    if (recordingBlob && postTranscription && client) {
      postTranscription(recordingBlob, client).then((data) => {setValue(data?.text || '')})
    }
  }, [recordingBlob])

  return (
    <IconButton
      color={ButtonColors.tertiary}
      onClick={handleOnClick}
      >
      {!isRecording && <FontAwesomeIcon icon={faMicrophone} title='Turn on processing speech to text' />}
      {isRecording && <FontAwesomeIcon icon={faSolidMicrophone} title='Stop' style={{ color: 'red' }} />}
    </IconButton>
  );
};
export default WhisperDictaphone;