import { DEFAULT_TERTIARY_COLORS } from "./default-theme";
import { APiPluginStyle } from "../../components/ThemeProvider/ThemeProvider.types";
import { getColorPallette } from "./general";

export const getTertiaryColor = (apiSettings: APiPluginStyle, windowSettings: any) => {
    const apiColour = apiSettings?.tertiaryColour;
    const apiTextColour = apiSettings?.tertiaryTextColour;
    const windowColour = windowSettings?.tertiaryColour;
    const windowTextColour = windowSettings?.tertiaryTextColour;
    let color = DEFAULT_TERTIARY_COLORS.main;
    let text = DEFAULT_TERTIARY_COLORS.mainContrast;

    if(apiColour) {
        color = apiColour;
    } else if(windowColour) {
        color = windowColour;
    }
    if (apiTextColour || windowTextColour) {
        text = apiTextColour || windowTextColour
    }

    return getColorPallette(color, text)
}