import React, { FC } from "react";
import styled from 'styled-components';
import { DateInfo } from '../DateInfo';
import { MessagesProvider } from '../MessageProvider';
import { LoadingMessageRow } from '../LoadingElement/LoadingMessageRow';
import { Languages, Senders } from "../../../types";
import { LoadingProps } from "./Loading.types";
import { useClientSettings } from "../../ClientSettingsProvider/ClientSettingsProvider";

export const LoadingElement: FC<LoadingProps> = ({withHeader, withInput, botAvatar}) => {
    return (
    <Div>
        <DateInfo eventTime={new Date()} isFirstMessage={true} lang={Languages.ENGLISH}/>
        <MessagesProvider
            isOperatorMsg={false}
            showAvatar={true}
            showDeliveryInfo={true}
            botAvatar={botAvatar}
            status={{sender: Senders.BOT, sent: true, timestamp: new Date().toString()}}
            typingAvatar=""
            sender={Senders.BOT}
        >
            <LoadingMessageRow
                key={`LOADING_ELEMENT}`}
            />
        </MessagesProvider>
    </Div>
 )
}

const Div = styled.div`
    width: auto;
    margin: 0px 10px 10px;
`