import styled from "styled-components";
import Button from "./Button";

const FullWidthButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

export default FullWidthButton;

