import styled from "styled-components";
import { StyledProps } from "./Header.types";

export const HEADER_HEIGHT = '52px'
export const GAP = '10px'
export const StyledHeader = styled.div<StyledProps>`
    display: flex;
    background: ${({ theme: {colors: {primary, advanced}} }) => advanced?.header || primary.gradient};
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.primary.mainContrast};
    height: ${HEADER_HEIGHT};
    border-radius: ${({ theme, fullScreen }) => fullScreen ? '0px' :
        `${theme.additionalStyles.bordersRadius.main} ${theme.additionalStyles.bordersRadius.main}`} 0 0;
    padding-left: 16px;
    padding-right: 8px;
    justify-content: space-evenly;
    flex-direction: column;
    `;

export const ActionContainer = styled.div<StyledProps>`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    width: auto;
    justify-content: space-between;
    margin-bottom: 0px;
`

export const Row = styled.div<StyledProps>`
    display: inline-flex;
    gap: ${GAP};
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 4px;
    margin-top: 4px;
`

export const Col = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Title = styled.h1<StyledProps>`
    font-size: ${({ theme }) => theme.fontSizes.large};
    line-height: ${({ theme }) => theme.fontSizes.large};
    font-weight: bold;
    text-align: ${({ align }) => align};
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Subtitle = styled.h2<StyledProps>`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: ${({ theme }) => theme.fontSizes.medium};
    text-align: ${({ align }) => align};
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ControlsButtonsRow = styled.div`
gap: 4px;
display: flex;
flex-direction: row;
justify-content: flex-end;
`;

export const LogoTitleRow = styled.div`
display: inline-flex;
align-items: center;
justify-content: left;
max-width: calc(100% - 90px);
`;