import { DEFAULT_THEME } from "./default-theme";
import { getPrimaryColor } from "./primary";
import { getSecondaryColor } from "./secondary";
import { getTertiaryColor } from "./tertiary";
import { getBackground } from "./background";
import { getNeutralColor } from "./neutral";
import { getWidth, getZIndex } from "./general";
import { APiPluginStyle } from "../../components/ThemeProvider/ThemeProvider.types";

export const getTheme = (apiSettings: APiPluginStyle, windowSettings: any) => {
    const width = getWidth(apiSettings,windowSettings);
    
    return {
        ...DEFAULT_THEME,
        colors: {
            ...DEFAULT_THEME.colors,
            primary: getPrimaryColor(apiSettings, windowSettings),
            secondary: getSecondaryColor(apiSettings, windowSettings),
            tertiary: getTertiaryColor(apiSettings, windowSettings),
            background: getBackground(apiSettings, windowSettings),
            // those colors are not available yet in the api, we can update them only via window
            neutral:  getNeutralColor(apiSettings, windowSettings), // we can improve this part, I did not want tto spend on it to much time without clear requirements
            advanced: {
                sendButton: apiSettings.sendButtonColour,
                header: apiSettings.headerColour,
                receiverMessage: apiSettings.receiverMessageColour,
                senderMessage: apiSettings.senderMessageColour
            }
        },
        additionalStyles: {
            ...DEFAULT_THEME.additionalStyles,
            width,
            zIndex: getZIndex(apiSettings, windowSettings)
        }
    }
}

