import { Languages } from "../../../types";

export const translatePlaceholderText = (lang: Languages): string => {
    switch (lang) {
        case Languages.WELSH:
            return 'Teipiwch neges...';
        default:
            return 'Write your message...'
    }
};
