import React, { useRef, useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { usePopper } from "react-popper";
import EmojiPickerLib, { EmojiClickData } from 'emoji-picker-react';
import IconButton from "../IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonColors } from "../Button/Button.types";
import { faFaceSmile } from "@fortawesome/pro-light-svg-icons";
import { EmojiPickerProps } from "./EmojiPicker.types";
import { EmojiPickerPopper, EmojiPickerWrapper, EmojiPickerInnerWrapper } from "./StyledEmojiPicker";
import { getEmojiPickerPositionProps } from "./utils";
import { useTextContainerContext } from "../TextContainer/TextContainerProvider";

const EmojiPicker: React.FC<EmojiPickerProps> = ({ onEmojiClick, onOpen }) => {
    const popperRef = useRef<any>(null)
    const ref = useOutsideClick(handleClosePopper);
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement | undefined>(undefined);
    const { textAreaRef } = useTextContainerContext()
    const { chatContainerRef } = useTextContainerContext()
    const { styles, attributes } = usePopper<any>(anchorEl, popperRef.current, getEmojiPickerPositionProps({ chatContainerRef: chatContainerRef?.current }));

    function handleClosePopper() {
        setAnchorEl(undefined)
        popperRef.current.setAttribute('data-show', false);
    }

    function handleOpen(event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (onOpen) onOpen()
        anchorEl ? setAnchorEl(undefined) : setAnchorEl(event?.currentTarget);
        popperRef.current.setAttribute('data-show', anchorEl ? false : true);
    }

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    async function handleEmojiClick(emojiData: EmojiClickData) {
        onEmojiClick(emojiData)
        handleClosePopper()
        await timeout(600); // delay to focus after animation
        if (textAreaRef?.current) textAreaRef.current.focus();
    }
    return (
        <EmojiPickerWrapper ref={ref}>
            <IconButton onClick={handleOpen} color={ButtonColors.tertiary}>
                <FontAwesomeIcon icon={faFaceSmile} title='Emoji Picker' />
            </IconButton>
            <EmojiPickerPopper
                ref={popperRef}
                style={styles.popper}
                {...attributes.popper}
            >
                <EmojiPickerInnerWrapper>
                    <EmojiPickerLib getEmojiUrl={(emoji) =>
                                `https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-apple/15.0.1/img/apple/64/${emoji}.png`
                            } lazyLoadEmojis={true} onEmojiClick={handleEmojiClick} />
                </EmojiPickerInnerWrapper>
            </EmojiPickerPopper>
        </EmojiPickerWrapper>

    )
}

export default EmojiPicker;
