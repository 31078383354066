import styled, { css } from "styled-components";

import { ButtonSizes, ButtonVariants, ButtonStyledProps } from './Button.types';
import {  FontSizes } from '../ThemeProvider/ThemeProvider.types';
import { getDisabledStyles, getElevatedStyles, getFilledStyles, getIconSize, getOutlinedStyles, getTextStyles, getTonedStatesStyles } from './styleHelper';
import { getBorderRadiusForOperator } from '../MessagesContainer/StyledMessageContainer';

const getPaddingWithIcon = (size: ButtonSizes, fontSizes: FontSizes) => {
  switch (size) {
    case ButtonSizes.small:
      return `calc(25px - ${fontSizes[size]})`;
    case ButtonSizes.large:
      return `calc(35px - ${fontSizes[size]})`;
    default:
      return `calc(30px - ${fontSizes[size]})`;
  }
};

export const StyledButton = styled.button<Required<ButtonStyledProps>>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  // SIZING
  border: 1px solid;
  font-size: ${({ theme, size }) => theme.fontSizes[size]};
  border-radius: ${({ theme, choice}) => choice ? getBorderRadiusForOperator(theme.additionalStyles.bordersRadius.button, false):  theme.additionalStyles.bordersRadius.button};
  font-family: inherit;
  line-height: 18px;
  padding: ${({ size }) => {
    switch (size) {
      case ButtonSizes.small:
        return "7px 25px 8px";
      case ButtonSizes.large:
        return "14px 35px 16px";
      default:
        return "6px 12px 6px 12px";
    }
  }};
  ${({ size, theme, ...props }) =>
    props["data-start"] &&
    css`
  padding-left: ${getPaddingWithIcon(size, theme.fontSizes)};
  `}
  ${({ size, theme, ...props }) =>
    props["data-end"] &&
    css`
  padding-right: ${getPaddingWithIcon(size, theme.fontSizes)};
  `}
  
  & svg {
    ${({ size, theme }) => getIconSize(theme.fontSizes[size])}
  }
    // VARIANT & COLOR
    ${({ variant, color, theme }) => {
      switch (variant) {
        case ButtonVariants.outlined:
          return getOutlinedStyles(theme.colors, color);
        case ButtonVariants.toned:
          return getTonedStatesStyles(
            theme.colors,
            color,
            theme.additionalStyles.elevation
          );
        case ButtonVariants.filled:
          return getFilledStyles(
            theme.colors,
            color,
            theme.additionalStyles.elevation
          );
        case ButtonVariants.elevated:
          return getElevatedStyles(
            theme.colors,
            color,
            theme.additionalStyles.elevation
          );
        default: // ButtonVariants.text is default
          return getTextStyles(theme.colors, color);
      }
    }}
  ${(props) => props.disabled && getDisabledStyles()}
`;
