import styled from "styled-components";

export const TooltipContent = styled.div<{ ref: any }>`
 text-align: left;
 position: relative;
 font-size: 0.8rem;
 max-width: 340px;
 visibility: hidden;
 background-color: rgba(0, 0, 0, 0.8);
 color: #fff;
 padding: 5px;
 border-radius: 5px;
 box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
 z-index: ${({ theme }) => theme.additionalStyles.zIndex + 13};

 &[data-show="true"] {
   visibility: visible;
 }
 
`;

export const Text = styled.p`
 margin: 0px;
`;