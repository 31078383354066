import React, { PropsWithChildren, useContext, useEffect, useState } from "react"
import ClientSettingsContext from "./ClientSettingsContext"
import { isMobile } from 'react-device-detect';

export type ClientSettingsContextType = {
    showLeft?: boolean,
    withHeader: boolean,
    withInput: boolean,
    fullScreen: boolean,
    enableEmojis?: boolean,
    enableFullScreen?: boolean,
    showDebugInformation: boolean,
    isMobileOrSmallScreen?: boolean,
    enableUploadFile?: boolean,
    enableSpeechToText?: boolean,
    enableWhisperSTT?: boolean,
    enableDownloadChat?: boolean,
    allowFeedback?: string,
    logicdialogPreview?: boolean,
    toggleFullScreen?: () => void,
    popupSize?: string,
    zoomLevel?: number
}

export type ClientSettingsContextPropsType = {
    open: boolean;
    withHeader: boolean,
    withInput?: boolean,
    fullScreenSettings: boolean,
    enableEmojis?: boolean,
    enableFullScreen?: boolean
    showDebugInformation?: boolean
    minPopupWidth?: number;
    enableUploadFile?: boolean;
    enableSpeechToText?: boolean;
    enableWhisperSTT?: boolean,
    enableDownloadChat?: boolean;
    showLeft?: boolean,
    allowFeedback?: string
    logicdialogPreview?: boolean;
    toggleFullScreen?: () => void,
    popupSize?: string
}

const ClientSettingsProvider = ({
    children, withHeader = true, withInput = true, minPopupWidth = 600, showLeft, enableUploadFile, fullScreenSettings, enableFullScreen, enableEmojis, enableSpeechToText, enableWhisperSTT, showDebugInformation, open,enableDownloadChat, popupSize, allowFeedback, logicdialogPreview = false
}: PropsWithChildren<ClientSettingsContextPropsType>) => {
    const isSmallScreen = window.innerWidth < minPopupWidth
    const isMobileOrSmallScreen = isMobile || isSmallScreen
    const fullScreen = isMobileOrSmallScreen ? true : fullScreenSettings

    const [localFullScreen, setLocalFullScreen] = useState(false)
    useEffect(() => {
        if (typeof fullScreen !== 'undefined') {
            setLocalFullScreen(fullScreen)
        }
    }, [fullScreen])

    useEffect(() => {
        if (isMobileOrSmallScreen && open) {
            setLocalFullScreen(isMobileOrSmallScreen)
        }
    }, [isMobileOrSmallScreen, open])

    const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio)
    useEffect(() => {
        const handleResize = () => {
            setZoomLevel(window.devicePixelRatio)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const toggleFullScreen = () => {
        setLocalFullScreen(!localFullScreen)
    }

    return (
        <ClientSettingsContext.Provider value={{
            withHeader,
            withInput,
            enableEmojis,
            showLeft,
            enableUploadFile,
            enableSpeechToText,
            enableWhisperSTT,
            enableDownloadChat,
            fullScreen: logicdialogPreview || localFullScreen,
            enableFullScreen,
            showDebugInformation : showDebugInformation || false,
            toggleFullScreen: toggleFullScreen,
            isMobileOrSmallScreen,
            zoomLevel,
            allowFeedback: logicdialogPreview ? undefined : allowFeedback, // hide feedback in the preview mode
            logicdialogPreview,
            popupSize
        }}>
                {children}
        </ClientSettingsContext.Provider>
    )
}

export default ClientSettingsProvider

export const useClientSettings = () => {
    return useContext(ClientSettingsContext)
}