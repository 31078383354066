var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { filterOutTyping } from "./utils";
export var EVENTS_ACTIONS_TYPES;
(function (EVENTS_ACTIONS_TYPES) {
    EVENTS_ACTIONS_TYPES["INIT"] = "init";
    EVENTS_ACTIONS_TYPES["UPDATE"] = "update";
})(EVENTS_ACTIONS_TYPES || (EVENTS_ACTIONS_TYPES = {}));
var INIT = EVENTS_ACTIONS_TYPES.INIT, UPDATE = EVENTS_ACTIONS_TYPES.UPDATE;
export function eventsReducer(events, action) {
    switch (action.type) {
        case INIT: {
            return action.events; // instead delete state is reset on init
        }
        case UPDATE: {
            var filteredData = {};
            for (var key in events) {
                if (events.hasOwnProperty(key)) {
                    filteredData[key] = filterOutTyping(events[key]);
                }
            }
            return __assign(__assign({}, filteredData), action.events);
        }
        default: {
            throw Error("Unknown action: " + action.type);
        }
    }
}
