import staging from './staging';
import development from './development';
import production from './production';
import { shallowMerge } from '../helpers';
import { Env } from './types';
var base = ({
    environment: process.env.NODE_ENV,
    defaultClient: process.env.DEFAULT_CLIENT,
    userApiBaseUrl: process.env.USER_API_BASE_URL,
    socketServerUrl: process.env.SOCKET_SERVER_URL,
    conversationApiBaseUrl: process.env.CONVERSATION_API_BASE_URL,
    socketServerApiUri: process.env.SOCKET_SERVER_API_URI,
});
console.log('using ENVIRONMENT:', base.environment);
var mergedConfig = base;
switch (base.environment) {
    case Env.PRODUCTION:
        mergedConfig = shallowMerge(production, base);
        break;
    case Env.STAGING:
        mergedConfig = shallowMerge(staging, base);
        break;
    default:
        mergedConfig = shallowMerge(development, base);
}
export var CONFIG = mergedConfig;
export * from './types';
