import styled, { css, keyframes } from "styled-components";

const DEFAULT_HEIGHT = 'calc(100% - 132px)';
export const SHOW_POPUP_CLASS ='wbbShowChat'
export const CLOSE_POPUP_CLASS ='wbbCloseChat'
// https://animista.net/
const scaleRightUp = keyframes`
0% {
    transform: scale(0.001);
    transform-origin: 100% 100%;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 100%;
  }
`

const scaleRightDown = keyframes`
0% {
    transform: scale(1);
    transform-origin: 100% 100%;
  }

  100% {
    transform: scale(0.001);
    transform-origin: 100% 100%;
  }
`


const scaleLeftUp = keyframes`
  0% {
      transform: scale(0.001);
      transform-origin: 0% 50%;
  }
  100% {
      transform: scale(1);
      transform-origin: 0% 50%;
  }
`

const scaleLeftDown = keyframes`
  0% {
      transform: scale(1);
      transform-origin: 0% 50%;
  }
  100% {
      transform: scale(0.001);
      transform-origin: 0% 100%;
  }
`

const WidgetContainerWrapper = styled.div<{ showLeft?: boolean,logicdialogPreview?:boolean, fullScreen?: boolean}>`
margin: 16px;
height: ${({ theme }) => theme.additionalStyles.height || DEFAULT_HEIGHT};
width: ${({ theme }) => theme.additionalStyles.width || '430px'};
position: ${({ logicdialogPreview }) => logicdialogPreview ?  'relative' : 'fixed'};
bottom: 20px;
display: none;
${props => props.showLeft ? ('left: 10px;') : ('right: 10px;')}
${({fullScreen}) => fullScreen && css`
height: 100%;
width: 100%;
margin: 0px;
left: 0;
right: 0;
bottom: 0;
`}

.${SHOW_POPUP_CLASS} {
    animation: ${props => props.showLeft ? scaleLeftUp : scaleRightUp} 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

}
.${CLOSE_POPUP_CLASS} {
    animation: ${props => props.showLeft ? scaleLeftDown : scaleRightDown} 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}`;

export default WidgetContainerWrapper;