import React, { FC } from 'react';
import { MessageRowProps } from './MessageContainer.types';
import { StyledMessageRow, MsgBubble, TextMessageContainer } from "./StyledMessageContainer";
import Avatar from '../Avatar';
import { useMessagesContext } from './MessageProvider';
import CustomEventsInfo from './InfoRow/CustomEventsInfo';
import { Senders } from '../../types';
import { SrOnly } from '../SrOnly/SrOnly';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useClientSettings } from '../ClientSettingsProvider/ClientSettingsProvider';
import { AgentActions, AgentActionsWrapper, getJustification} from './AgentActions/AgentActions';

export const MessageRow: FC<MessageRowProps> = ({ children, content, isTypingIndicator, msgFooter }) => {
    const { isOperatorMsg, botAvatar: currentAvatar, showAvatar, typingAvatar, sender, onEditResponse, operator} = useMessagesContext()
    const { logicdialogPreview, zoomLevel, showDebugInformation } = useClientSettings()
    const avatar = isTypingIndicator ? typingAvatar : currentAvatar // use typingAvatar if it is a typing indicator component otherwise use avatar passed from props

    const srLabel = sender === Senders.USER ? 'You said' : `The ${sender} said`

    return (
        <>
            <StyledMessageRow data-operator={isOperatorMsg} zoomLevel={zoomLevel}>
                <Avatar
                    src={avatar}
                    alt={`Avatar image of the ${sender}`}
                    role='presentation'
                    display={showAvatar}
                    chatAvatar={true}
                    crossOrigin={logicdialogPreview ? "anonymous" : undefined}
                />
                <AgentActionsWrapper  
                    justification={ getJustification( showDebugInformation, onEditResponse, sender, operator ) }>
                    <TextMessageContainer>
                        <MsgBubble data-operator={isOperatorMsg} typing-indicator={isTypingIndicator} zoomLevel={zoomLevel}>
                            {!isTypingIndicator && <SrOnly>{srLabel}</SrOnly>}
                            {
                                isTypingIndicator ?
                                    content :
                                    <ReactMarkdown
                                        components={{
                                            a: (props: { [x: string]: any; node: any; }) => {
                                                const { node, ...rest } = props
                                                return <a target="new" {...rest} />
                                            }
                                        }}
                                        remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
                            }
                        </MsgBubble>
                        {children}
                        {msgFooter && msgFooter}
                    </TextMessageContainer>
                    <AgentActions />
                </AgentActionsWrapper>
            </StyledMessageRow>
            <CustomEventsInfo />
        </>
    )
}