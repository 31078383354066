import { DEFAULT_PRIMARY_COLORS } from "./default-theme";
import { APiPluginStyle } from "../../components/ThemeProvider/ThemeProvider.types";
import { getColorPallette } from "./general";

export const getPrimaryColor = (apiSettings: APiPluginStyle, windowSettings: any) => {
    const apiColour = apiSettings?.primaryColour;
    const apiTextColour = apiSettings?.primaryTextColour;
    const windowColour = windowSettings?.primaryColour;
    const windowTextColour = windowSettings?.textColour;
    let color = DEFAULT_PRIMARY_COLORS.main;
    let text = DEFAULT_PRIMARY_COLORS.mainContrast;

    if (apiColour) {
        color = apiColour;
    } else if (windowColour) {
        color = windowColour;
    }
    if (apiTextColour || windowTextColour) {
        text = apiTextColour || windowTextColour
    }

    const palette = getColorPallette(color, text)
    return {
        ...palette,
    }
}
