import React, { useCallback } from "react";
import IconButton from "../../IconButton/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ButtonColors, ButtonSizes } from "../../Button/Button.types";
import { useMessagesContext } from "../MessageProvider";
import Tooltip from "../../Tooltip/Tooltip";
import { faMessagePen } from "@fortawesome/pro-light-svg-icons";

export const EditResponse = () => {
    const {blockId, onEditResponse} =  useMessagesContext();

    const handleEditResponse = useCallback(
      () => {
        if(onEditResponse && blockId) onEditResponse(blockId)
      },
      [blockId, onEditResponse],
    )

    if (!blockId || !onEditResponse) {
        return null
    }
  
    return (
      <Tooltip content='Edit'>
        <IconButton color={ButtonColors.tertiary} size={ButtonSizes.small} onClick={handleEditResponse} >
            <FontAwesomeIcon icon={faMessagePen} />
        </IconButton>
      </Tooltip>
    )
};