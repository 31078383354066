import React from 'react'
import styled from 'styled-components'
import Checkbox, { CheckboxProps } from '../Checkbox/Checkbox'

function EnterSettings({checked,disabled, onChange}: Omit<CheckboxProps, 'label'> ) {
   return (
    <EnterSettingsContainer>
        <Checkbox 
            checked={checked}
            disabled={disabled}
            label="Send message when ENTER pressed"
            onChange={onChange} />
    </EnterSettingsContainer>
   ) 
}

export default EnterSettings;

const EnterSettingsContainer = styled.div`
width: 100%;
padding-left: 8px;
padding-top: 8px
`