import React, { PropsWithChildren, useContext } from "react";
import { PersistentMenuItem } from "../Header/Header.types";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";

type TextContainerContextType = {
    showMediaPanelBtn?: boolean;
    enableEmojis?: boolean;
    onAttachFile: (file: File, url: string | null | ArrayBuffer) => void
    persistentMenu?: PersistentMenuItem[];
    onClickPersistentMenuItem?: (item: PersistentMenuItem) => void;
    textAreaRef?: React.RefObject<HTMLTextAreaElement>;
    msgsContainerRef?: React.RefObject<HTMLDivElement> | undefined,
    enableUploadFile?: boolean;
    enableSpeechToText?: boolean;
    enableWhisperSTT?: boolean;
    chatContainerRef?: React.RefObject<any>;
    postTranscription?: (blob: Blob, client: string) => Promise<void | {
        text: string;
    }>;
    client?: string;
};

export type TextContainerContextPropsType = {
    onAttachFile: (file: File, url: string | null | ArrayBuffer) => void;
    persistentMenu?: PersistentMenuItem[];
    onClickPersistentMenuItem?: (item: PersistentMenuItem) => void;
    textAreaRef?: React.RefObject<HTMLTextAreaElement>;
    msgsContainerRef?: React.RefObject<HTMLDivElement> | undefined,
    chatContainerRef?: React.RefObject<any>;
    postTranscription?: (blob: Blob, client: string) => Promise<void | {
        text: string;
    }>
    client?: string
};

const TextContainerContext = React.createContext<TextContainerContextType>({
    onAttachFile: () => { }
});

const TextContainerProvider = ({ children, onAttachFile, textAreaRef, persistentMenu, onClickPersistentMenuItem, chatContainerRef, msgsContainerRef, postTranscription, client }: PropsWithChildren<TextContainerContextPropsType>) => {
    const { enableUploadFile, enableEmojis, enableSpeechToText, withInput, enableWhisperSTT } = useClientSettings()
    if (!withInput) return (<></>)
    return (
        <TextContainerContext.Provider value={{
            showMediaPanelBtn: Boolean(enableUploadFile || enableSpeechToText || enableEmojis),
            msgsContainerRef,
            enableUploadFile, enableSpeechToText, onAttachFile, textAreaRef, enableEmojis, persistentMenu, onClickPersistentMenuItem, chatContainerRef, enableWhisperSTT, postTranscription, client
        }}>
            {children}
        </TextContainerContext.Provider>
    )
}

export default TextContainerProvider

export const useTextContainerContext = () => {
    return useContext(TextContainerContext);
};
