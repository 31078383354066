import React, { FC } from "react";
import styled from "styled-components";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";

interface BackdropProps {
  children?: React.ReactNode;
  onClick?: () => void;
  role?: string;
}

const StyledBackdrop = styled.div<{fullScreen?: boolean, left?:boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 30%);
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.additionalStyles.zIndex + 3};
  border-radius: ${({ theme, fullScreen, left}) => fullScreen ?  '0px' : left ? theme.additionalStyles.bordersRadius.leftSideChatContainer :theme.additionalStyles.bordersRadius.chatContainer};
`;

const Backdrop: FC<BackdropProps> = ({ children, onClick, ...props }) => {
  const { fullScreen, showLeft } = useClientSettings()
  return <StyledBackdrop onClick={onClick} fullScreen={fullScreen} left={showLeft} {...props}>{children}</StyledBackdrop>;
};

export default Backdrop;
