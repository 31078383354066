import styled from "styled-components";
import IconButton from "./IconButton";
import { ButtonColors, ButtonProps, ButtonVariants } from "../Button/Button.types";
import React, { PropsWithChildren } from "react";
import { applyShadow } from "../../inner-utils";
import { getContrastColorToBackground } from "../Button/styleHelper";

export const IconButtonOnPrimary = (props
    : PropsWithChildren<Omit<ButtonProps, 'text'>>) => <StyledIconButtonOnPrimary variant={ButtonVariants.text} {...props} />

const StyledIconButtonOnPrimary = styled(IconButton)`
border: none;
height: 20px; // together with margin it must be 24px https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum.html
width: 20px;
color: ${({ theme }) => theme.colors.primary.mainContrast};
&:is(:hover, :focus-visible, :active){
    background-color: transparent;
    color: ${({ theme }) => getContrastColorToBackground(ButtonColors.primary, theme.colors.primary.main, theme.colors)};
}
`

export const ControlsButtons = styled(IconButton)`
height: 20px; // together with margin it must be 24px https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum.html
width: 20px;
margin: 2px;
padding: 0px;
background-color: ${({ theme }) => theme.colors.primary.mainContrast};
color: ${({ theme }) => theme.colors.primary.main};
box-shadow: ${applyShadow(4)}
`

export const HeaderButtonWrapper = styled.div`
align-items: center;
display: inline-flex;
&> button  > svg {
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    height: 10px;
    width: auto;
    height: 10px;
    width: auto;
  }
`;
