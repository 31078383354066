import { jsx as _jsx } from "react/jsx-runtime";
import { render } from "react-dom";
import App from './App';
// import reportWebVitals from './reportWebVitals';
// https://developer.mozilla.org/en-US/docs/Web/API/Web_components/Using_shadow_DOM
// get our shadow HOST
var host = document.querySelector('#uniqueIdForWidget');
// create a shadow root inside it
var shadow = host.attachShadow({ mode: 'open' });
// create a slot where we will attach the StyleSheetManager
var styleSlot = document.createElement('section');
// append the styleSlot inside the shadow
shadow.appendChild(styleSlot);
// create the element where we would render our app
var renderIn = document.createElement('div');
// append the renderIn element inside the shadow
styleSlot.appendChild(renderIn);
function HtmlTagWrapper(Component) {
    render(_jsx(Component, { mainDiv: host, styleSlot: styleSlot }), renderIn);
}
var WrappedWidget = HtmlTagWrapper(App);
export default {
    ChatWidget: WrappedWidget,
};
