import  Color from  'color'
import { DEFAULT_ADDITIONAL_STYLES, DEFAULT_BACKGROUND_COLORS } from './default-theme'
import { APiPluginStyle } from "../../components/ThemeProvider/ThemeProvider.types";
// https://github.com/Qix-/color#readme


export const getColorPallette = (color: string, contrastText: string) => {
    // to make double sure that for example default settings with client settings would not make text unreadable
    const  textColour = checkBackgroundTextContrast(color,  contrastText || getWCAGTextColour(color)) 
    return {
        main: color,
        mainContrast: textColour,
        container:  getContainerColor(color),
        containerContrast: getContainerContrastColor(color)
    }
}

export const getBackgroundPallette = (color: string, contrastText: string) => {
    // to make double sure that for example default settings with client settings would not make text unreadable
    const  textColour = checkBackgroundTextContrast(color,  contrastText || getWCAGTextColour(color)) 
    return {
        ...DEFAULT_BACKGROUND_COLORS,
        main: color,
        mainContrast: textColour,
    }
}

// to make double sure that for example default settings with client settings would not make text unreadable
export const checkBackgroundTextContrast = (color: string, contrastText: string) => {
    const colorObj = Color(color);
    const textColorObj = Color(contrastText);
    if(colorObj.contrast(textColorObj) < 2) {
        return getWCAGTextColour(color)
    }
    return contrastText
}

export const getWidth = (apiSettings: APiPluginStyle, windowSettings: any) => {
    let width = DEFAULT_ADDITIONAL_STYLES.width
    const windowWidth = windowSettings?.width
    const appSettingsWidth = apiSettings?.width 
    if(appSettingsWidth) {
        width=  appSettingsWidth + 'px'
    } else if(windowWidth){
        width = windowWidth + 'px'
    }
    return width
}

/**
 * 
 * @param colour hex value
 * @returns Return contrasted colour for text
 *  based on WCAG standards
 */
const getWCAGTextColour = (colour: string) => {
    const colorObj = Color(colour);
    return colorObj.contrast(Color('#ffff')) < 2? '#000000' : '#ffff';
};

const getContainerColor = (color: string) => {
    const colorObj = Color(color);
   return colorObj.lighten(0.4).fade(0.6).rgb().string()
}

const getContainerContrastColor = (color: string) => {
    const colorObj = Color(color);
    return colorObj.darken(0.5).hex()
}

export const getZIndex = (apiSettings: APiPluginStyle, windowSettings: any) => {
   return apiSettings?.zindex || windowSettings?.style?.zIndex || 2000
}
