import 'regenerator-runtime/runtime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { ButtonColors } from '../Button/Button.types';
import { faMicrophone } from "@fortawesome/pro-light-svg-icons";
import IconButton from '../IconButton';

interface Props {
  enableSpeechToText?: boolean;
  setValue: (value: string) => void
  onToggle: () => void;
}

const Dictaphone: React.FC<Props> = ({ setValue,onToggle, enableSpeechToText }) => {
  const {
    transcript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const handleOnMouseDown = () => {
    SpeechRecognition.startListening({ language: 'en' });
  }

  const handleOnMouseUp = () => {
    SpeechRecognition.stopListening();
    onToggle()
  }

  useEffect(() => {
    if (transcript) setValue(transcript);
  }, [transcript])

  if (!browserSupportsSpeechRecognition || navigator.userAgent.match('CriOS') || !enableSpeechToText) {
    return <span title="Turn on processing speech to text"></span>
  }

  return (
    <IconButton
      color={ButtonColors.tertiary}
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}>
      <FontAwesomeIcon icon={faMicrophone} title='Turn on processing speech to text' />
    </IconButton>
  );
};
export default Dictaphone;