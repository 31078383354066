import React, { FC } from 'react';
import { MediaRowProps } from '../MessageContainer.types';
import { MsgBubble, StyledMessageRow } from "../StyledMessageContainer";
import { ImagePreview } from './ImagePreview';
import { ChoicesWrapper } from '../../ChoiceMessage/ChoicesWrapper';
import { VideoPreview } from './VideoPreview';
import Avatar from '../../Avatar';
import { useMessagesContext } from '../MessageProvider';
import styled from 'styled-components'
import FilePreview from './FilePreview';
import CustomEventsInfo from '../InfoRow/CustomEventsInfo';
import { Senders } from '../../../types';
import { SrOnly } from '../../SrOnly/SrOnly';
import { useClientSettings } from '../../ClientSettingsProvider/ClientSettingsProvider';
import { AgentActions, AgentActionsWrapper, getJustification } from '../AgentActions/AgentActions';

export const MediaRow: FC<MediaRowProps> = ({ content, fileName, msgFooter }) => {
    const { isOperatorMsg, botAvatar, showAvatar, onEditResponse, sender, operator } = useMessagesContext()
    const getAriaLabel = `The ${sender} send ${content.image ? 'image' : ''} ${content.video ? 'video' : ''}  `
    const srLabel = sender === Senders.USER ? 'You send media attachment' : `The ${sender} send media attachment`
    const { logicdialogPreview, zoomLevel, showDebugInformation} = useClientSettings()

    return (
        <>
            <StyledMessageRow data-operator={isOperatorMsg} aria-label={getAriaLabel} role="region" zoomLevel={zoomLevel}>
                <Avatar
                    src={botAvatar}
                    alt={`Avatar image of the ${sender}`}
                    display={showAvatar}
                    chatAvatar={true}
                    crossOrigin={logicdialogPreview ? "anonymous" : undefined}
                />
                <AgentActionsWrapper  
                    justification={ getJustification( showDebugInformation, onEditResponse, sender, operator ) }>
                    <Column>
                        <SrOnly>{srLabel}</SrOnly>
                        {content.file && (
                            <MsgBubble data-operator={isOperatorMsg} zoomLevel={zoomLevel}>
                                <FilePreview file={content.file} fileName={fileName} />
                            </MsgBubble>)
                        }
                        <ChoicesWrapper data-operator={isOperatorMsg}>
                            {content.image && <ImagePreview image={content.image} />}
                            {content.video && <VideoPreview video={content.video} />}

                        </ChoicesWrapper>
                        {msgFooter}
                    </Column>
                    <AgentActions />
                </AgentActionsWrapper>
            </StyledMessageRow>
            <CustomEventsInfo />
        </>
    )
}

const Column = styled.div`
display: flex;
flex-direction: column;
`
