import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";

export const StyledFileUploader = styled(FileUploader)`
height: 100%;
display: flex;
`
export const FileTypesIcons = styled.div`
display: flex;
gap: 10px;
`;

export const FileUploadDescription = styled.span`
font-size: 14px;
font-weight: 300;
`
export const FileUploadContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
border: 3px dotted ${({ theme }) => theme.colors.primary.main};
border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main};
padding: 16px;
text-align: center;
color: ${({ theme }) => theme.colors.background.mainContrast};
`
export const FileLabelRow = styled.div`
align-items: center;
gap: 8px;
font-weight: 600;
font-size: 20px;
line-height: 30px;
`
export const UploadFileWrapper = styled.div<{left?: boolean}>` // code from rasa overlap
display: flex;
border-radius: ${({ theme, left }) =>  left ? theme.additionalStyles.bordersRadius.chatContainer :`${theme.additionalStyles.bordersRadius.chatContainer}`};
height: 100%;
background-color: ${({ theme }) => theme.colors.background.main};;
width: 100%;
justify-content: center;
`

export const InnerUploadFileDiv = styled.div<{left?: boolean, zoomLevel?: number}>`
    padding: ${({zoomLevel}) => zoomLevel && zoomLevel > 2 ? `16px` : `32px` };
    & > label {
        border-color:  ${({ theme }) => theme.colors.primary.main};
        border-radius: ${({ theme, left }) =>  left ? theme.additionalStyles.bordersRadius.chatContainer :`${theme.additionalStyles.bordersRadius.chatContainer}`};
        display: flex;
        height: 100%;
        }
    }
`;
