import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  HeaderButtonWrapper,
  ControlsButtons,
} from "../IconButton/IconButtonOnPrimary";
import PopperComponent from "../Popper";
import ConfirmationModal from "../Popper/Confirmation";
import { ButtonSizes } from "../Button/Button.types";
import { getText, MODAL_KEYS } from "../../types/lang"
import { useLanguageContext } from "../LanguageProvider";

interface EndChatProps {
  onClickEndChat: () => void;
}
const EndChat: React.FC<EndChatProps> = ({ onClickEndChat }) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const { lang } = useLanguageContext();

  const togglePopper = () => {
    setIsPopperOpen((prev) => !prev);
  };

  return (
    <HeaderButtonWrapper>
      <ControlsButtons onClick={togglePopper} size={ButtonSizes.small}>
        <FontAwesomeIcon icon={faXmark} title="Close chat" />
      </ControlsButtons>
      {isPopperOpen && (
        <PopperComponent
        titleId='wbb-confirmation-title'
          content={
            <ConfirmationModal
              title={ getText ( MODAL_KEYS.EXIT_CHAT, lang ) }
              titleId='wbb-confirmation-title'
              text=""
              onCancel={togglePopper}
              onContinue={onClickEndChat}
              submitText={ getText ( MODAL_KEYS.YES, lang ) }
              cancelText={ getText ( MODAL_KEYS.NO, lang ) }
            />
          }
        />
      )}
    </HeaderButtonWrapper>
  );
};


export default EndChat;
