import styled, { keyframes } from 'styled-components';
import { TextMessageContainer } from '../StyledMessageContainer';

const dropAnimation = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const LoadingItem = styled.div`
  padding: 0;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  right: 0px;
  left: 0px;
  top: 40%;
  bottom: 0px;
  margin-right: 5px;
  width: 15px;
  height: 15px;		
  border-radius:100% 100% 100% 100%;
  animation: ${dropAnimation} 1s infinite;
`;

export const ItemOne = styled(LoadingItem)`
  animation-delay: 0s;
`;

export const ItemTwo = styled(LoadingItem)`
  animation-delay: 0.2s;
`;

export const ItemThree = styled(LoadingItem)`
  animation-delay: 0.4s;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadingTxtMessageContainer = styled(TextMessageContainer)`
  display: grid;
`