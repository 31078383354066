import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/pro-light-svg-icons"
import { HeaderButtonWrapper, IconButtonOnPrimary } from "../IconButton/IconButtonOnPrimary"
import styled from 'styled-components'

export function DownloadChat({ onClick }: { onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }) {
    return (
        <Div>
            <IconButtonOnPrimary onClick={onClick}>
                <FontAwesomeIcon icon={faDownload} title='Download conversation button' />
            </IconButtonOnPrimary>
        </Div>
    )
}

const Div = styled(HeaderButtonWrapper)`
&> button { 
    padding: 0px;
    padding-right: 2px;
}
&> button > svg {
    height: 16px;
    width: auto;
}
`
