import ObjectId from "bson-objectid";
import { MsgTypes, Senders } from "../types/events/enum";
import { Session } from "../types/session/Session";
import {
  SendChoiceMsgPayload,
  SendFileMsgPayload,
  SendTextMsgPayload,
} from "../types/events/Message";
import { EventRecord } from "../types/events/eventsTypes";
import { Choice } from "../types/events/Choice";

/**
 * Creating a text message payload to sent to the server
 */
export const generateTextMsg = (
  session: Session,
  text: string
): SendTextMsgPayload => {
  const eventId = ObjectId().toHexString();
  return { ...session, eventId, text };
};

export const generateAgentTextMsg = (
  text: string,
  draftEventId?: string
): { eventId: string; text: string, draftEventId?: string } => {
  const eventId = ObjectId().toHexString();
  return { eventId, text, draftEventId };
};

export const generateChoiceMsg = (
  session: Session,
  choice: Choice
): SendChoiceMsgPayload => {
  const eventId = ObjectId().toHexString();
  return { ...session, eventId, choice };
};

export const generateFileMsg = (
  session: Session,
  blob: File
): SendFileMsgPayload => {
  const eventId = ObjectId().toHexString();
  return {
    ...session,
    eventId: eventId,
    blob,
  };
};

export const formatStringToSent = (
  text: string,
  _id: string,
  operator: Senders,
  choice?: Choice
): EventRecord => {
  return {
    [_id]: [
      {
        deliveryInfo: {
          sent: false,
          timestamp: new Date().toString(),
          sender: operator,
          delivered: null,
          read: null,
        },
        type: choice ? MsgTypes.CHOICE : MsgTypes.TEXT,
        block: {
          type: MsgTypes.TEXT,
          content: {
            text,
          },
        },
        choice,
      },
    ],
  };
};

export const formatAttachmentToSent = (
  blob: File,
  _id: string,
  operator: Senders,
  url: string
): EventRecord => {
  const isImage = blob.type.split("/")[0] === "image";
  return {
    [_id]: [
      {
        deliveryInfo: {
          sent: false,
          timestamp: new Date().toString(),
          sender: operator,
          delivered: null,
          read: null,
        },
        type: MsgTypes.ATTACHMENT,
        block: {
          type: MsgTypes.ATTACHMENT,
          content: {
            ...(isImage ? { image: url } : { file: url }),
          },
          fileName: blob.name,
        },
      },
    ],
  };
};
