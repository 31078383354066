import React from 'react'
import { ButtonColors } from "../Button/Button.types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import IconButton from "../IconButton/IconButton";
import styled from "styled-components";

export function SliderArrowRight(props: any) {
    const { className, style, onClick } = props;

    return (
        <Div
        className={className}
        style={{ ...style, display: "block", right: 0, zIndex: 1, color: "black" }}
    >
        <IconButton color={ButtonColors.tertiary} onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} title='Slide right, to the next gallery item'/>
        </IconButton>
        </Div>
    );
}

export function SliderArrowLeft(props: any) {
    const { className, style, onClick } = props;

    return (
        <Div
            className={className}
            style={{ ...style, display: "block", left: '-14px', zIndex: 1, color: "black"}}
        >
            <IconButton color={ButtonColors.tertiary} onClick={onClick}>
                <FontAwesomeIcon icon={faChevronLeft} title='Slide right, to the previous gallery item' />
            </IconButton>
        </Div>
    );
}

const Div = styled.div`
.slick-next:before {
    content: none;
}
slick-prev:before {
    content: none;
}
& > button { 
    &:hover {
        background: ${({theme}) => theme.colors.background.main};
    }
}
`