import React from "react";
import { useClientSettings } from "../ClientSettingsProvider/ClientSettingsProvider";
import { useMessagesContext } from "./MessageProvider";
import { FooterWrapper } from "./StyledMessageContainer";

export const MessageFooterWrapper = ({children, isOperatorMsg}: {isOperatorMsg?: boolean; children?: JSX.Element | (JSX.Element | null)[]}) => {
    const {  showDeliveryInfo, status } = useMessagesContext()
    const {allowFeedback, zoomLevel} = useClientSettings();

    if(!allowFeedback && !(status && showDeliveryInfo)){
        return null;
    }
    return(
        <FooterWrapper data-operator={isOperatorMsg} zoomLevel={zoomLevel}>
            {children}
        </FooterWrapper>
    )
}