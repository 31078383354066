export var SwrKeys;
(function (SwrKeys) {
    SwrKeys["CLIENT_INFO"] = "client-info";
    SwrKeys["EVENTS"] = "events";
    SwrKeys["PERSISTENT_MENU"] = "persistent-menu";
    SwrKeys["START_CHAT"] = "start-chat";
    SwrKeys["END_CHAT"] = "end-chat";
    SwrKeys["SESSION"] = "session";
    SwrKeys["HEADER_INFO"] = "header-info";
})(SwrKeys || (SwrKeys = {}));
