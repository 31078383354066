import styled, { css, keyframes } from "styled-components"
import { getIconSize } from "../Button/styleHelper"
import { applyShadow } from "../../inner-utils";

const pulsateFwd = keyframes`
0% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
50% {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
`;

export const StyledPopupButtonDiv = styled.div<{ open: boolean, showLeft?: boolean, 'data-open'?: boolean, shrinkButton?: boolean, zoomLevel?: number, popupSize?: string; customButtonPadding?: string }>`
  & > button.menu-open{
    background: ${({ theme: { colors: { primary, advanced } } }) => advanced?.sendButton || primary.main};
    color: ${({ theme }) => theme.colors.primary.mainContrast};
    border-color: ${({ theme }) => theme.colors.primary.mainContrast};
    border: none;
    box-shadow: ${({ theme }) => applyShadow(theme.additionalStyles.elevation)};
    border-radius: ${({ theme, showLeft }) => `${showLeft ? theme.additionalStyles.bordersRadius.leftSideChatContainer : theme.additionalStyles.bordersRadius.chatContainer}`};
    position:fixed;
    transform:translate3d(0,0,0);
    transition:transform ease-in 200ms;
    cursor: pointer;
    z-index:${({ theme }) => theme.additionalStyles.zIndex + 2};
    transition-timing-function:cubic-bezier(0.175, 0.885, 0.320, 1.275);
    transition-duration:400ms;
    transform:scale(1,1) translate3d(0,0,0);
    cursor:pointer;
    padding: ${({ popupSize }) => { 
      if ( popupSize ) {
        switch ( popupSize ) { 
          case "large" : 
            return "22px"
          case "medium" : 
            return "16px";
          default : 
            return "10px";  
        } 
      } else { 
        return "10px"
      }
    }};
    margin: 16px;
    ${props => props.showLeft ?
    css`left: 10px;`
    :
    css`right: 10px;`}
    bottom: 20px;
      &-start {
        -webkit-animation: ${pulsateFwd} 0.5s ease-in-out 1.5 both;
        animation: ${pulsateFwd} 0.5s ease-in-out 1.5 both;
      }
      &:hover {
        scale: 1.05;
      }
    ${props => props.shrinkButton && css`
    padding: 14px 18px 16px 19px;`}
    ${props => props.customButtonPadding && props.customButtonPadding}
    ${props => { 
      return props.zoomLevel && props.zoomLevel > 2 ? `
      margin: 8px !important;
      bottom: 10px !important;  
    `: ``}}
  }

  ${({ open, showLeft }) => open ? css`
  & > button.menu-open{
    transition-timing-function:linear;
    transition-duration:800ms;
    transform:scale(1.8,1.8) translate3d(0,0,0);
    ${showLeft ?
      css`left: 10px;`
      :
      css`right: 10px;`}
      bottom: 20px;
    }`
     : css`
    & > button.menu-open {
      transition-timing-function:cubic-bezier(0.165, 0.840, 0.440, 1.000);
      transition-duration: 500ms;
      transform:translate3d(${showLeft ? '-22px' : '22px'},22px,0);
    }`
    }
  
`

export const OpenPopupLabel = styled.div<{ popupSize: string }>`
display: flex;
flex-direction: row;
align-items: center;
gap: 8px;
font-family: "Poppins-light", sans-serif;
font-weight: 400;
& svg {
    ${({ theme }) => getIconSize(theme.fontSizes.small)}
  }
`

export const OpenPopupText = styled.span<{ popupSize: string }>`
  font-size: ${({ theme, popupSize }) => { 
    if ( popupSize ) {
      switch ( popupSize ) { 
        case "large" : 
          return theme.fontSizes.popupSize.large;
        case "medium" : 
          return theme.fontSizes.popupSize.medium;
        default : 
          return theme.fontSizes.popupSize.small;  
      } 
    } else { 
      return theme.fontSizes.medium
    }
  }};
  aria-hidden: true;
`

export const ClosePopupIcon = styled.div<{ left?: boolean }>`
border-radius: ${({ theme, left }) => left ? theme.additionalStyles.bordersRadius.leftSideChatContainer : `${theme.additionalStyles.bordersRadius.chatContainer}`};
& svg {
  font-size: 8px
width: 10px;
height: 10px;
    transform: scale(1.39,1.39);
    position: relative;
  }
`