import {
  EventRecord,
  EventTypes,
  FormattedEvent,
  MsgTypes,
  Senders,
  WbbEvent,
} from "../types";
import { fromMsgEvent } from "./messages";

const {
  MESSAGE,
  TRACKING,
  AGENT_INTERVENTION,
  USER_INTERVENTION,
  INTERVENTION_END,
  AGENT_ASSIGNED,
  AGENT_UNASSIGNED,
  CLOSE_EXTENSION,
  CONVERSATION_START,
  TYPING,
  LANGUAGE,
} = EventTypes;

export const getAgent = (
  interventionAgent?: string,
  nameObject?: { firstName: string; lastName: string }
): string => {
  if (nameObject) {
    return nameObject.firstName;
  } else {
    return interventionAgent ? getAgentFromEmail(interventionAgent) : "Agent";
  }
};

const getAgentFromEmail = (email: string): string => {
  return email.split("@")[0];
};

export const processSessionEvents = (
  events: WbbEvent[],
  operator: Senders
): EventRecord => {
  let newEvents: any = {};
  events.forEach((event: WbbEvent) => {
    const formattedEvent = formatEvent(event, operator);
    if (formattedEvent) {
      newEvents = { ...newEvents, ...formatEvent(event, operator) };
    }
  });
  return newEvents;
};

export const formatEvent = (
  event: WbbEvent,
  operator: Senders
): FormattedEvent => {
  const type: EventTypes = event.type;
  const agent = getAgent(
    event?.intervention?.agent,
    event?.intervention?.agentName
  );
  let formattedEvent;
  switch (type) {
    case LANGUAGE:
      formattedEvent = formatInfo(
        `Language${event.language?.blocked ? " not" : ""} changed from ${
          event.language?.prevLanguage
        } to ${event.language?.newLanguage}: ${
          event.language?.reason
        }`,
        event.created
      );
      break;
    case MESSAGE:
      formattedEvent = fromMsgEvent(event, operator);
      break;
    case AGENT_INTERVENTION:
      formattedEvent = formatInfo(
        `${agent} started live customer assistance`,
        event.created,
        true
      );
      break;
    case USER_INTERVENTION:
      formattedEvent = formatInfo(
        "Customer requested assistance",
        event.created,
        true
      );
      break;
    case INTERVENTION_END:
      if (event?.intervention?.resolution?.topic === "agent removed") {
        formattedEvent = formatInfo(
          `${agent} cancelled live customer assistance`,
          event.created,
          true
        );
      } else if (event?.intervention?.agent) {
        formattedEvent = formatInfo(
          `${agent} finished live customer assistance`,
          event.created,
          true
        );
      }
      break;
    case AGENT_ASSIGNED:
      formattedEvent = formatInfo(
        `${agent} joined the conversation`,
        event.created,
        true
      );
      break;
    case AGENT_UNASSIGNED:
      formattedEvent = formatInfo(
        `${agent} was unassigned to intervention`,
        event.created,
        true
      );
      break;
    case TRACKING:
      const message = event.message;
      if (message.type === "url-track" && message.url) {
        const url = new URL(message.url);
        // const truncated = ellipsize(url.host + url.pathname, 30);
        formattedEvent = formatInfo(
          `Customer clicked a`,
          event.created,
          false,
          url.href
        );
      }
      break;
    case CLOSE_EXTENSION:
      break;
    case CONVERSATION_START:
      break;
    case TYPING:
      formattedEvent = {
        type: EventTypes.TYPING,
        deliveryInfo: { delivered: event.created },
      };
      break;
    default:
      break;
  }
  if (!formattedEvent) return;
  return {
    [event._id]: Array.isArray(formattedEvent)
      ? formattedEvent
      : [formattedEvent],
  };
};

const formatInfo = (
  message: string,
  date: string,
  intervention?: boolean,
  href?: string
): any => {
  return {
    type: intervention ? MsgTypes.INTERVENTION_INFO : MsgTypes.INFO,
    deliveryInfo: { delivered: date, timestamp: date },
    content: {
      info: message,
      href: href,
    },
  };
};
