import React, { useEffect } from "react";
import Button from "../Button";
import { ButtonColors, ButtonVariants } from "../Button/Button.types";
import { ModalContent, ModalFooter, ModalText, ModalTitle } from "./StyledModal";
import { useLanguageContext } from "../LanguageProvider";
import { Polylang } from "../../types";
import { useHotkeys, useHotkeysContext } from "react-hotkeys-hook";

interface CloseChatConfirmationProps {
  title: string | Polylang;
  text: string | Polylang;
  titleId: string;
  defaultTitle?: string;
  defaultText?: string;
  onCancel: () => void;
  onContinue: () => void;
  submitText?: string;
  cancelText?: string;
}

const ConfirmationModal: React.FC<CloseChatConfirmationProps> = ({
  title,
  text,
  titleId,
  onCancel,
  onContinue,
  submitText,
  cancelText,
  defaultTitle,
  defaultText
}) => {
  const { lang } = useLanguageContext();
  const { enableScope, disableScope } = useHotkeysContext();

  useEffect(() => {
    enableScope('modalScope')
    disableScope('mainWidgetScope')
  }, [])

  const escapeMethod = () => {
    enableScope('mainWidgetScope')
    disableScope('modalScope')
    if (titleId === 'wbb-session-idle-modal') {
      return onContinue()

    } else {
      return onCancel()
    }
  }

  useHotkeys('Escape', () => {
    escapeMethod()
  }, { scopes: ['modalScope'] })

  return (

    <ModalContent>
      <ModalTitle tabIndex={-1} id={titleId}>{typeof title === 'string' ? title : title[lang] || defaultTitle}</ModalTitle>
      <ModalText>{typeof text === 'string' ? text : text[lang] || defaultText}</ModalText>
      <ModalFooter>
        <Button
          tabIndex={0}
          onClick={onContinue}
          text={submitText || "Yes"}
          color={ButtonColors.primary}
          variant={ButtonVariants.toned}

        />
        <Button
          onClick={onCancel}
          text={cancelText || "No"}
          color={ButtonColors.secondary}
          variant={ButtonVariants.toned}
        />
      </ModalFooter>
    </ModalContent>
  );
};

export default ConfirmationModal;
