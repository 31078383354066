import React from 'react'
import { Div, TRH, TRItem, Table } from './StyledRasaDebug';
import { RasaDebugInfo } from '../../types';
import { kebabToTitle } from '../../inner-utils/textFormat';
import { useClientSettings } from '../ClientSettingsProvider/ClientSettingsProvider';
import { ScrollBar } from '../ScrollBar/ScrollBar';

function DebugInfoOverlap(props: Partial<RasaDebugInfo>)  {
    const { showLeft } = useClientSettings()

    const getConfidence = (confidence: number) =>
        ((confidence / 1) * 100).toFixed(2) + "%";

    const entities = props?.entities?.filter((item) => item.entity && item.value)

    return (
        <Div left={showLeft}>
            <ScrollBar  width={ "100%" }>
                <Table >
                    <TRH>
                        <td>Rank</td>
                        <td>Intent</td>
                        <td>Confidence</td>
                    </TRH>
                    {props?.intent_ranking?.filter((item) => item.confidence && item.title).map((item, index: number) => {
                        return (
                            <TRItem key={item.name + '-' +index}>
                                <td>{index + 1}</td>
                                <td>{kebabToTitle(item.title || '')}</td>
                                <td>{getConfidence(item.confidence)}</td>
                            </TRItem>
                        )
                    })}
                </Table>
                {entities?.length ?(
                <Table >
                    <TRH>
                        <td>Entity</td>
                        <td>Value</td>
                    </TRH>
                    {entities.map((item, index) => {
                        return (
                            <TRItem key={item.entity + '-' +index}>
                                <td>{item.entity}</td>
                                <td>{item.value}</td>
                            </TRItem>
                        )
                    })}
                </Table>
                ) : null}
            </ScrollBar>
        </Div>

    )
}

export default DebugInfoOverlap
