import useSWR from "swr";
import { UserService } from "../../api/userService/UserService";
import { SwrKeys } from "./keys";
var useClientInfo = function (payload) {
    var _a = useSWR(SwrKeys.CLIENT_INFO, function () {
        return UserService.getClientInfo(payload);
    }), clientInfo = _a.data, error = _a.error, isLoading = _a.isLoading;
    return { clientInfo: clientInfo, error: error, isLoading: isLoading };
};
export default useClientInfo;
