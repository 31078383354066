import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-light-svg-icons";
import IconButton from "../IconButton";
import { ButtonColors } from "../Button/Button.types";
import {  useOverlapContext } from "../OverlapProvider/OverlapProvider";
import { useTextContainerContext } from "../TextContainer/TextContainerProvider";
import { OverlapComponentTypes } from "../OverlapProvider/OverlapProvider.types";

function UploadFile() {
    const { onOpen } = useOverlapContext()
    const {onAttachFile} = useTextContainerContext()

    function handleOpen() {
        onOpen({ componentType: OverlapComponentTypes.UploadFile, props: {onAttachFile} })
    }
 
    return (
        <IconButton color={ButtonColors.tertiary} onClick={handleOpen}>
            <FontAwesomeIcon icon={faImage} title="Click here to upload file or simply drag and drop any file you wish to share into this window."/>
        </IconButton>
    )
}

export default UploadFile;