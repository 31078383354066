import { DEFAULT_SECONDARY_COLORS } from "./default-theme";
import { APiPluginStyle } from "../../components/ThemeProvider/ThemeProvider.types";
import { getColorPallette } from "./general";

export const getSecondaryColor = (apiSettings: APiPluginStyle, windowSettings: any) => {
    const apiColour = apiSettings?.secondaryColour;
    const apiTextColour = apiSettings?.secondaryTextColour;
    const windowColour = windowSettings?.secondaryColour;
    const windowTextColour = windowSettings?.secondaryTextColour;
    let color = DEFAULT_SECONDARY_COLORS.main;
    let text = DEFAULT_SECONDARY_COLORS.mainContrast;

    if(apiColour) {
        color = apiColour;
    } else if(windowColour) {
        color = windowColour;
    }
    if (apiTextColour || windowTextColour) {
        text = apiTextColour || windowTextColour
    }

    
    const palette = getColorPallette(color, text)
    return {
        ...palette,
    }
}